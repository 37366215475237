import React from 'react'
import Oops from "../../assets/Opps.json";
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import Lottie from 'lottie-react';

const AcceptRejectModal = ({
    message,
    isOpen,
    onRequestClose,
    onCancel,
    onSelect,
    isDelete,
}) => {
    return (
        <ComponentAndScreenConstant.CommonModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <div className='warningOrAlert'>
                <Lottie
                    loop={true}
                    animationData={Oops}
                    className="delAccModalErrLottie"
                />
                {message}
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button className='cancelButton' onClick={onCancel}>No</button>
                    <button onClick={onSelect} style={{ backgroundColor: '#BC0000' }}>Yes</button>
                </div>
            </div>
        </ComponentAndScreenConstant.CommonModal>
    )
}

export default AcceptRejectModal
