import React from 'react'
import './RecruiterInfo.css'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { useLocation } from 'react-router-dom'

function RecruiterInfo() {
    const locationData = useLocation()
    const { job } = locationData?.state

    return (
        <div className='recruiterInfoContainer'>
            <div className='breadCrumbDiv'>
                <ComponentAndScreenConstant.Breadcrumbs currentPage={["Recruiter Info", "Job Details",`${locationData?.state?.screen ?? 'suggested'} Job`]} />
            </div>
            <div className='recruiterInfoDiv'>
                <div className='recruiterInfoLeftDiv'>
                    <img src={require('../../assets/recruiter.png')} />
                    <h1>{job?.jobPoster?.fullName}</h1>
                    <p>{job?.company?.companyName}</p>
                </div>
                <div className='recruiterInfoRightDiv'>
                    <div className='recruiterBasicInfo'>
                        <label>Mobile No :</label>
                        <p>+{job?.jobPoster?.countryCode?.countryCode + ' ' + job?.jobPoster?.mobileNumber}</p>
                    </div>
                    <div className='recruiterBasicInfo'>
                        <label>Email Id :</label>
                        <p
                            onClick={() => {
                                return
                                window.open(`mailto:${job?.jobPoster?.userName}?subject=SendMail&body=Description`, '_blank')
                            }}>{job?.jobPoster?.userName}</p>
                    </div>
                    <div className='recruiterBasicInfo'>
                        <label>LinkedIn Profile :</label>
                        <p
                            onClick={() => {
                                window.open(job?.jobPoster?.linkedinProfile, '_blank')
                            }}>{job?.jobPoster?.linkedinProfile}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RecruiterInfo