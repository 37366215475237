import './JobStatusDetails.css'
import Oops from "../../assets/Opps.json";
import { Url } from '../../EnvoirnmentSetup/APIs'
import React, { useEffect, useState } from 'react'
import searchIcon from '../../assets/searchIcon.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelectedCardContext } from '../../Context/Context'
import { getAPICall, postAPICall } from '../../APIMethods/APIMethods'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { IoBagRemoveSharp } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdPin } from "react-icons/io";
import Lottie from 'lottie-react';
import moment from 'moment';

const JobStatusDetails = () => {
  const navigate = useNavigate()
  const locationData = useLocation()
  const { Flow, jobId, job } = locationData?.state
  const currentPage = [`${Flow ?? 'Applied'}`, "Job Details", `Jobs`]
  const { IsLoading, setIsLoading, setWarning, setAlert } = useSelectedCardContext()
  const [refereshData, setRefereshData]  = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [SelectModal, setSelectModal] = useState(false)
  const [ApplicantList, setApplicantList] = useState([])
  const [FilterApplicantList, setFilterApplicantList] = useState([])
  const [isDeleting,setIsDeleting]=useState(false)

  useEffect(() => {
    getAllApplicant()
  }, [])

  useEffect(() => {
    getAllApplicant()
  }, [refereshData])

  const getAllApplicant = () => {
    console.log('>>>>>>>>>>>', Flow)
    let jobData;
    if (Flow == "Hired") {
      jobData = {
        selected: true,
        job: {
          jobId: jobId,
        },
      };
    } else if (Flow == "Viewed") {
      jobData = {
        viewed: true,
        job: {
          jobId: jobId,
        },
      };
    } else if (Flow == "Send To") {
      jobData = {
        sendTo: true,
        job: {
          jobId: jobId,
        },
      };
    } else if (Flow == "Declined") {
      jobData = {
        closed: true,
        job: {
          jobId: jobId,
        },
      };
    } else if (Flow == "Shortlisted") {
      jobData = {
        interviewShortlisted: true,
        job: {
          jobId: jobId,
        },
      };
    } else if (Flow == "Interviewing") {
      jobData = {
        interviewScheduled: true,
        job: {
          jobId: jobId,
        },
      };
    } else {
      jobData = {
        intrested: true,
        job: {
          jobId: jobId,
        },
      };
    }
    setIsLoading(true)
    postAPICall(Url.GetAppliedApplicantList, jobData)
      .then((res) => {
        if (res.SUCCESS) {
          console.log('>>>>>>>>>>>', res)
          setIsLoading(false)
          let d = res?.DATA?.map((i) => {
            let keywordsString = i?.skill?.map((k, index) => k?.name).join(', ') ?? ''
            return { ...i, keywordsString }
          })
          setApplicantList(d)
          setFilterApplicantList(d)
        }
      })
  }

  const ShortListCandidate = () => {
    if (!selectedUser?.userId)
      return null

    let obj = isDeleting?{
      job: {
        jobId: jobId
      },
      closed: true,
      user: {
        userId: selectedUser?.userId
      }
    }:{
      job: {
        jobId: jobId
      },
      selected: true,
      user: {
        userId: selectedUser?.userId
      }
    }
    setIsLoading(true)
    postAPICall(Url?.postApplyJob, obj)
      .then((res) => {
        setIsLoading(false)
        if (res?.SUCCESS) {
          cancelSelect()
          getAllApplicant()
          setAlert(`Candidate ${isDeleting ? 'Rejected' : 'Hired'} Successfully!`)
        } else {
          setWarning(res?.message)
        }
        setIsDeleting(false)
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false)
      })
  }

  const changeApplicantStatus = async (mobileNumber, userId, type) => {
    if (type == 'mobile') {
      setAlert("Mobile number of applicant is " + mobileNumber)
    } else if (type == 'whatsapp') {
      handleClick(mobileNumber, 'whatsapp')
    } else if (type == 'mail') {
      handleClick(mobileNumber, 'mail')
    } else if (type == 'linkedin') {
      handleClick(mobileNumber);
    }
    let PositionStatus = {
      ...type != 'linkedin' ? {
        recruiterContacted: true,
      } : {
        recruiterView: true,
      },
      user: {
        userId: userId,
      },
      job: {
        jobId,
      },
    };
    postAPICall(Url?.postApplyJob, PositionStatus)
      .then((res) => console.log(res));
  };
  const handleShortlisted = (userId,type) =>{
    let PositionStatus = { 
      ...type != 'schedule' ? {interviewShortlisted : true} : {interviewScheduled : true},
      user: {
        userId: userId,
      },
      job: {
        jobId,
      },
    };
    postAPICall(Url?.postApplyJob, PositionStatus)
      .then((res) => {
        if(res?.SUCCESS){
          setAlert(`Student ${type != 'schedule' ? 'Shortlisted' : 'Interview Scheduled'} Successfully!`)
        }
      });
  }
  const handleClick = (link, type) => {
    // Change the location to Google.com
    if (type == "mail") {
      link = `mailto:${link}`;
    } else if (type == "whatsapp") {
      link = "https://wa.me/" + link;
    }
    window.open(link);
  };

  const handleSortChange = (e) => {
    if (e == '2') {
      const sortedData = ApplicantList.sort((a, b) => b?.skill.length - a?.skill.length);
      setFilterApplicantList([...sortedData])
    }
    else if (e == '3') {
      const sortedData = ApplicantList.sort((a, b) => b?.experience?.experienceId - a?.experience?.experienceId);
      setFilterApplicantList([...sortedData])
    }
    else if (e == '1') {
      setFilterApplicantList([...ApplicantList])
    }
  }

  const cancelSelect = () => {
    setSelectModal(false)
    setIsDeleting(false)
    setSelectedUser(null)
  }
console.log('>>>>>>>>>>>', FilterApplicantList)
  return (
    <div className='similarJobsMainSection'>
      <div className="similarJobBreadCrumb">
        <ComponentAndScreenConstant.Breadcrumbs currentPage={currentPage} header={`${Flow ?? 'Applied'} - ${job?.designation[0]?.designationName}`} />
      </div>
      <div className='similarAllJobs'>
        <div className='sortAndSearch'>
          <div className='flex-10gap'>
            <div className='iconsWithInfo'>
              <IoBagRemoveSharp />
              <small>{job?.experience?.experience}</small>
            </div>
            <div className='iconsWithInfo'>
              <FaCalendarAlt />
              <small>{moment(job?.formattedActiveTillDate).format('DD-MMM-YYYY')}</small>
            </div>
            <div className='iconsWithInfo locationWithInfo' title={job?.state?.map(state => state.stateName).join(", ")}>
              <IoMdPin />
              <small className='locationDetailsShow'> {job?.state?.map(state => state.stateName).join(", ")}</small>
            </div>
          </div>
          <div className='search'>
            <img className='searchIcon' src={searchIcon} alt='' />
            <input
              className='JobDetDashboardSearch' type="text" placeholder='Search'
              onChange={(e) => {
                let searchValue = e.target.value;
                setFilterApplicantList(ApplicantList.filter((item) => {
                  return (
                    ((item?.fullName?.toString()).toLowerCase()).includes(searchValue.toLowerCase()) ||
                    ((item?.experience?.experience.toString()).toLowerCase()).includes(searchValue.toLowerCase()) ||
                    ((item?.keywordsString.toString()).toLowerCase()).includes(searchValue.toLowerCase())
                  )
                }))
              }} />
          </div>
          <div className='sort'>
            <select className="sortSelect" name="" id="" onChange={(e) => { handleSortChange(e.target.value) }}>
              {/* <option value="">Select</option> */}
              <option value="1">Sort by Applied Date</option>
              <option value="2">Sort by Keywords</option>
              <option value="3">Sort by Experience</option>
            </select>
          </div>
        </div>
        <div className='similarJobsCards'>
          {FilterApplicantList.length > 0 ? <>
            {FilterApplicantList.map((item) => (
              <div className={`seperateJobCardSimilar ${Flow == "Applied" && "seperateJobCardSimilarApplied"}`} key={item?.userId} >
                <ComponentAndScreenConstant.ApplicantDetailsCard
                  flow={Flow}
                  setRefereshData={setRefereshData}
                  refreshData={refereshData}
                  applicantDetails={item}
                  onHireClick={() => {
                    if (item?.selected) return
                    setSelectedUser(item)
                    setSelectModal(true)
                  }}
                  onDeclinedClick={() => {
                    if (item?.closed) return
                    setSelectedUser(item)
                    setSelectModal(true)
                    setIsDeleting(true)
                  }}
                  onShortlistClick={()=>{handleShortlisted(item?.userId,'shortlist')}}
                  onScheduledClick={()=>{handleShortlisted(item?.userId,'schedule')}}
                  onMailClick={() => changeApplicantStatus(item?.userName, item?.userId, 'mail')}
                  onMessageClick={() => changeApplicantStatus(item?.mobileNumber, item?.userId, 'whatsapp')}
                  onLinkedInClick={() => changeApplicantStatus(item?.linkedinProfile, item?.userId, 'linkedin')}
                  onCallClick={() => changeApplicantStatus(`+${item?.countryCode?.countryCode} ${item?.mobileNumber}`, item?.userId, 'mobile')}
                />
              </div>
            ))}</> : <div className='notFound'>
            <img src={require('../../assets/NotFound.png')} style={{ height: '30vh', width: '30vh', }} />
            No Data Found
          </div>}
        </div>
      </div>
      <ComponentAndScreenConstant.CommonModal
        isOpen={SelectModal}
        onRequestClose={cancelSelect}
      >
        <div className='selectModal'>
          <span className='closePreferenceModal' onClick={cancelSelect}>X</span>
          <Lottie
            loop={true}
            animationData={Oops}
            className="delAccModalErrLottie"
          />
          <p>Are you sure you want to {isDeleting ? 'Reject' :'hire'} <b>{selectedUser?.fullName} </b> ?</p>
          {selectedUser?.keywordsString && <p>with following skills ' {selectedUser?.keywordsString} '</p>}
          <div className='buttonDiv'>
            <button onClick={cancelSelect}>Cancel</button>
            <button style={{ backgroundColor: '#01774A', color: '#fff' }} onClick={ShortListCandidate}>{isDeleting ? 'Reject':'Hire'}</button>
          </div>
        </div>
      </ComponentAndScreenConstant.CommonModal>
    </div>
  )
}

export default JobStatusDetails