import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { useSelectedCardContext } from "../../Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import searchIcon from "../../assets/searchIcon.svg";
import React, { useEffect, useState } from "react";
import { Url } from "../../EnvoirnmentSetup/APIs";
import { getAPICall } from "../../APIMethods/APIMethods";
import "./JobDashboard.css";

const JobDashboard = () => {
  const navigate = useNavigate();
  const locationData = useLocation();
  const {
    selectedCard,
    setSelectedCard,
    setIsLoading,
    setWarning,
    setAlert,
    jobModal,
    setJobModal,
    jobCreateDetails,
    setJobCreateDetails,
    filterOnBack,
    setFilterOnBack,
    contextJobData,
    setContextJobData,
  } = useSelectedCardContext();
  const screenName = locationData?.state?.screen ?? "latest";
  const [loader, setLoader] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [jobList2, setJobList2] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [label, setLable] = useState("My Jobs");
  const [jobsCount, setJobsCount] = useState(0);

  useEffect(() => {
    !filterOnBack && getAllJobs();
  }, [isChecked, jobModal]);

  

  useEffect(() => {
    setSelectedCard(1);
    if (jobList2?.length > 0) {
      setJobList(
        jobList2?.filter((item) => {
          return (
            item?.designation[0]?.designationName
              ?.toLowerCase()
              ?.includes(searchVal?.toLowerCase()) ||
            item?.experience?.experience
              ?.toLowerCase()
              ?.includes(searchVal?.toLowerCase()) ||
            item?.jobType?.jobType
              ?.toLowerCase()
              ?.includes(searchVal?.toLowerCase()) ||
            item?.workMode?.workMode
              ?.toLowerCase()
              ?.includes(searchVal?.toLowerCase()) ||
            item?.company?.companyName
              ?.toLowerCase()
              ?.includes(searchVal?.toLowerCase()) ||
            item?.stateString
              ?.toLowerCase()
              ?.includes(searchVal?.toLowerCase()) ||
            item?.skillString?.toLowerCase()?.includes(searchVal?.toLowerCase())
          );
        })
      );
    }
  }, [searchVal]);

  useEffect(() => {
    const filteredData = contextJobData?.filter((item) => {
      return (
        item?.designation[0]?.designationName
          ?.toLowerCase()
          ?.includes(filterOnBack?.toLowerCase()) ||
        item?.experience?.experience
          ?.toLowerCase()
          ?.includes(filterOnBack?.toLowerCase()) ||
        item?.jobType?.jobType
          ?.toLowerCase()
          ?.includes(filterOnBack?.toLowerCase()) ||
        item?.workMode?.workMode
          ?.toLowerCase()
          ?.includes(filterOnBack?.toLowerCase()) ||
        item?.company?.companyName
          ?.toLowerCase()
          ?.includes(filterOnBack?.toLowerCase()) ||
        item?.stateString
          ?.toLowerCase()
          ?.includes(filterOnBack?.toLowerCase()) ||
        item?.skillString?.toLowerCase()?.includes(filterOnBack?.toLowerCase())
      );
    });
    setJobList(filteredData);
    setJobsCount(filteredData?.length);
  }, [filterOnBack]);

  const getAllJobs = () => {
    setLoader(true);
    const userDetails = sessionStorage.getItem("user");
    let apiUrl = "";
    if (isChecked) {
      apiUrl = Url.getJobPostedByUser.replace(
        "{userId}",
        JSON.parse(userDetails)?.userId
      );
    } else {
      apiUrl = Url.getLatestAllJobs;
    }
    getAPICall(apiUrl).then((res) => {
      setLoader(false);
      let d = res?.DATA?.map((i) => {
        let stateStr = i?.state?.map((s, ind) => s.stateName).join(", ") ?? "";
        let skillStr = i?.skill?.map((k, index) => k?.name).join(", ") ?? "";
        return { ...i, stateString: stateStr, skillString: skillStr };
      });
      setJobList(d);
      setJobList2(d);
      setContextJobData(d);
      setJobsCount(res?.DATA?.length);
    });
  };

  const handleSortChange = (e) => {
    const selectedValue = e.target.value;
    // Implement sorting logic based on selectedValue
    let sortedList = [...jobList];
    if (selectedValue === "1") {
      sortedList?.sort(
        (a, b) =>
          new Date(b.postedDate ?? b.job.postedDate) -
          new Date(a.postedDate ?? a.job.postedDate)
      );
    } else if (selectedValue === "2") {
      sortedList?.sort(
        (a, b) =>
          new Date(b.tillDate ?? b.job.tillDate) -
          new Date(a.tillDate ?? a.job.tillDate)
      );
    } else if (selectedValue === "3") {
      sortedList?.sort(
        (a, b) =>
          (a?.experience?.experienceId ?? a?.job?.experience?.experienceId) -
          (b?.experience?.experienceId ?? b?.job?.experience?.experienceId)
      );
    }
    setJobList(sortedList);
  };

  const handleChange = (e) => {
    setIsChecked(!isChecked);
    setFilterOnBack("");
  };

  useEffect(() => {
    getAPICall(Url.instituteAll)
      .then((res) => {
        if (res?.SUCCESS) {
          sessionStorage.setItem(
            "instituteDetails",
            JSON.stringify(res?.DATA[0])
          );
        }
        if (res.DATA?.length < 0) {
          navigate("/Main/InstituteInfo");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="JobDashboardMainDiv">
      <div className="headingRowJobDashboardDiv">
        <div className="JobDetDashboardMainDiv">
          <div className="containerToggle">
            <div className="toggle-switch">
              <input
                type="checkbox"
                className="checkbox"
                name={"toggleSwitch"}
                id={label}
                checked={isChecked}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <label className="label" htmlFor={label}>
                <span className="inner" />
                <span className="switch">
                  {isChecked ? "My Jobs" : "All Jobs"}
                  <span>{` ${jobsCount ? "(" + jobsCount + ")" : 0}`}</span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="searchAndSortDiv">
          <div className="search">
            <img className="searchIcon" src={searchIcon} alt="" />
            <input
              className="JobDetDashboardSearch"
              type="text"
              maxLength={15}
              placeholder="Search Jobs"
              value={filterOnBack}
              onChange={(e) => {
                setSearchVal(e?.target?.value);
                setFilterOnBack(e?.target?.value);
              }}
            />
          </div>
          <div className="sort">
            <select
              className="sortSelect"
              name=""
              id=""
              onChange={(e) => {
                handleSortChange(e);
              }}
            >
              <option value="1">Sort by Posted Date</option>
              <option value="2">Sort by Active Date</option>
              <option value="3">Sort by Experience</option>
            </select>
          </div>
        </div>
      </div>
      <div className="jobCards">
        {jobList?.length > 0 ? (
          <>
            {jobList?.map((item) => (
              <div
                className="latestSeperateJobCard"
                key={item?.jobId}
                onClick={(e) => {
                  navigate("/Main/jobDetails", {
                    state: {
                      jobId:
                        screenName == "applied"
                          ? item?.job?.jobId
                          : item?.jobId,
                      headerHide: true,
                      screen: screenName,
                      encryptedJobId: item?.encryptedJobId,
                    },
                  });
                }}
              >
                <ComponentAndScreenConstant.jobCard
                  jobDetails={screenName == "applied" ? item?.job : item}
                  applied={screenName == "applied"}
                  applicationStatus={screenName == "applied" ? item : undefined}
                  onEditClick={(e) => {
                    e.stopPropagation();
                    let Data = {
                      ...item,
                      messageAuditId: 0,
                      skill:
                        item?.skill?.map((v) => ({
                          ...v,
                          value: v.skillId,
                          label: v.name,
                        })) ?? [],
                      designation:
                        item?.designation?.map((v) => ({
                          ...v,
                          value: v.designationId,
                          label: v.designationName,
                        })) ?? [],
                      jobType: {
                        ...item.jobType,
                        value: item.jobType.jobTypeId,
                        label: item.jobType?.jobType,
                      },
                      workMode: {
                        ...item.workMode,
                        value: item.workMode.workModeId,
                        label: item.workMode?.workMode,
                      },
                      experience: {
                        ...item.experience,
                        value: item.experience.experienceId,
                        label: item.experience?.experience,
                      },
                      state:
                        item?.state?.map((v) => ({
                          ...v,
                          value: v.stateId,
                          label: v.stateName,
                        })) ?? [],
                    };
                    console.log(">>>>>>>>>>>", Data);
                    setJobCreateDetails(Data);
                    setJobModal(true);
                  }}
                />
              </div>
            ))}
          </>
        ) : (
          <div
            className="notFound"
            onClick={() => {
              setJobModal(true);
            }}
          >
            <img
              src={require("../../assets/NotFound.png")}
              style={{ height: "30vh", width: "30vh" }}
            />
            Click Here to create Job
          </div>
        )}
      </div>
      <ComponentAndScreenConstant.LoaderModal setLoader={loader} />
    </div>
  );
};

export default JobDashboard;
