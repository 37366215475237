import React, { useEffect, useState } from "react";
import "./TeamManagement.css";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import CommonTable from "../../Components/table/CommonTable";

import { Url } from "../../EnvoirnmentSetup/APIs";
import {
  deleteAPICall,
  getAPICall,
  postAPICall,
  putAPICall,
} from "../../APIMethods/APIMethods";

import { useSelectedCardContext } from "../../Context/Context";

import delIcon from "../../assets/deleteicon.svg";
import editIcon from "../../assets/editicon.svg";
import closePopup from "../../assets/closepopup.svg";
import teamManagement from "../../assets/teamgementheader.svg";

import {
  field,
  onlyAlphabets,
  onlyNumber,
  emailValidationForJobPoster,
  mobileNumberValidations,
} from "../../Validation/Validation";

const TeamManagement = () => {
  const [tableData, setTableData] = useState([]);
  const tableColumns = [
    {
      Header: "Sr. No",
      accessor: "srno",
    },
    {
      Header: "Placement Coordinator Name",
      accessor: "placementCoordinatorName",
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
      Cell: ({ row }) => (
        <div>
          <span>{`${ "+" + row?.original?.countryCode + " " + row?.original?.mobileNumber}`}</span>
        </div>
      ),
    },
    {
      Header: "Email ID",
      accessor: "email",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div>
          <span
            style={{
              color:
                row?.original?.status.toLowerCase() === "active"
                  ? "#01774A"
                  : "#EA6200",
            }}
          >
            {row?.original?.status}
          </span>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      hideSortIcon: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <div
          className="actionitems"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {row?.original?.status.toLowerCase() !== "active" && (
            <button onClick={(e) => editPlacementCoordinator(row?.original)}>
              {" "}
              <img src={editIcon} alt="" />{" "}
            </button>
          )}
          <button onClick={() => deletePlacementCoordinator(row?.original)}>
            {" "}
            <img src={delIcon} alt="" />{" "}
          </button>
        </div>
      ),
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: field,
    mobileNumber: field,
    userName: field,
    userId: null,
    countryCode: {
      countryCodeId: "249",
    },
  });


  const [updatePopup, setUpdatePopup] = useState(false);
  const [handleSearchData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const { setIsLoading, setWarning, setAlert } = useSelectedCardContext();

  // -------------------------------------------------- API Integration ---------------------------------------------

  const fetchTableData = async () => {
    try {
      setIsLoading(true);
      const tableData = await getAPICall(Url?.managersTeam);

      if (tableData.SUCCESS) {
        setIsLoading(false);
        const newArr = tableData?.DATA?.filter(
          (items) =>
            items?.status.toLowerCase() === "active" ||
            items?.status.toLowerCase() === "pending"
        ).map((item, i) => {
          return {
            srno: i + 1,
            placementCoordinatorName: item?.fullName,
            mobileNumber: item?.mobileNumber,
            email: item?.userName,
            userId: item?.userId,
            status: item?.status,
            countryCode : item?.countryCode?.countryCode
          };
        });
        setTableData(newArr);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------- Add placement Coordinator ----------------
  const addPlaementCoordinatorData = async () => {
    if (!formData?.fullName?.fieldValue) {
      setWarning("Placement Coordinator Name is required.");
  } else if (!formData?.mobileNumber?.fieldValue) {
      setWarning("Mobile Number is required.");
  } else if (formData?.mobileNumber?.fieldValue.length < 4) {
      setWarning("Mobile number is not valid.");
  } else if (!formData?.userName?.fieldValue) {
      setWarning("Email ID is required.");
  } else if (formData?.fullName?.isValidField === false) {
      setWarning("Full Name is not valid, please check.");
  } else if (formData?.mobileNumber?.isValidField === false) {
      setWarning("Mobile Number is not valid, please check.");
  } else if (formData?.userName?.isValidField === false) {
      setWarning("Email ID is not valid, please check.");
  } else {  
      try {
        const bodyToSend = {
          web: true,
          fullName: formData?.fullName?.fieldValue,
          mobileNumber: formData?.mobileNumber?.fieldValue,
          userName: formData?.userName?.fieldValue,
          countryCode: {
            countryCodeId: formData?.countryCode?.countryCodeId,
          },
          roles: [
            {
              name: "ROLE_RECRUTER",
            },
          ],
        };

        setIsLoading(true);
        const addData = await postAPICall(Url?.managerUserAdd, bodyToSend);

        if (addData?.SUCCESS) {
          setAlert("Placement Coordinator Added Successfully.");
          fetchTableData();
          setOpenModal(false);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setWarning(addData?.message);
        }
      } catch (err) {
        setWarning("Placement Coordinator Added failed.");
        setOpenModal(false);
        setIsLoading(false);
      }
    }
  };

  // ------------------ delete placement Coordinator -----------------------
  const deletePlacementCoordinator = async (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };

  const deleteRecord = async () => {
    const userId = deleteData?.userId;
    try {
      setIsLoading(true);
      const deleteManager = await deleteAPICall(
        Url?.deleteManagerUser?.replace("{userId}", userId)
      );

      if (deleteManager?.SUCCESS) {
        setDeleteModal(false);
        setAlert("Placement Coordinator Deleted Successfully.");
        fetchTableData();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setDeleteModal(false);
        setWarning(deleteManager?.message);
      }
    } catch (err) {
      setWarning("Failed to Delete the Placement Coordinator.");
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

  // edit placement coordinator
  const editPlacementCoordinator = async (data) => {
    fetchEditData(data);
  };

  // fetch to edit the data when edit is clicked
  const fetchEditData = async (data) => {
    const userId = data?.userId;
    try {
      setIsLoading(true);
      const getDataForEdit = await getAPICall(
        Url?.getManagerUserById?.replace("{userId}", userId)
      );
      if (getDataForEdit?.SUCCESS) {
        setIsLoading(false);
        setOpenModal(true);
        setUpdatePopup(true);
        setFormData({
          ...formData,
          fullName: { ...field, fieldValue: getDataForEdit?.DATA?.fullName },
          mobileNumber: {
            ...field,
            fieldValue: getDataForEdit?.DATA?.mobileNumber,
          },
          userName: { ...field, fieldValue: getDataForEdit?.DATA?.userName },
          userId: getDataForEdit?.DATA?.userId,
          countryCode: getDataForEdit?.DATA?.countryCode
        });
      } else {
        setOpenModal(false);
        setIsLoading(false);
      }
    } catch (err) {
      setOpenModal(false);
      setIsLoading(false);
    }
  };

  // Update the placement co-ordinator
  const updatePlacementCoordinator = async () => {
    const bodyToSend = {
      userId: formData?.userId,
      web: true,
      fullName: formData?.fullName?.fieldValue,
      mobileNumber: formData?.mobileNumber?.fieldValue,
      userName: formData?.userName?.fieldValue,
      countryCode: {
        countryCodeId: formData?.countryCode?.countryCodeId,
      },
      roles: [
        {
          name: "ROLE_RECRUTER",
        },
      ],
    };

    if (!formData?.fullName?.fieldValue) {
      setWarning("Placement Coordinator Name is required.");
    } else if (!formData?.mobileNumber?.fieldValue) {
      setWarning("Mobile Number is required.");
    } else if (formData?.mobileNumber?.fieldValue.length < 4) {
      setWarning("Mobile number is not valid.");
    } else if (!formData?.userName) {
      setWarning("Email ID is required.");
    } else {
      try {
        setIsLoading(true);
        const updateData = await putAPICall(
          Url?.updateManagerByUserId,
          bodyToSend
        );

        if (updateData?.SUCCESS) {
          setIsLoading(false);
          fetchTableData();
          setUpdatePopup(false);
          setOpenModal(false);
          setAlert("Placement Coordinator Updated Successfully.");
        } else {
          setOpenModal(false);
          setIsLoading(false);
          setWarning(updateData?.message);
        }
      } catch (err) {
        setIsLoading(false);
        setWarning("Failed to Update the Placement Coordinator.");
      }
    }
  };
  // -------------------------------------------------- API Integration -------------------------------------------------------

  const handleAddPlacementCoordinator = () => {
    setUpdatePopup(false);
    setOpenModal(true);
    setFormData({
      fullName: null,
      mobileNumber: null,
      userName: null,
      userId: null,
      countryCode: {
        countryCodeId: "249",
      },
    });
  };

  const closeAddPlacementCoordinator = () => {
    setOpenModal(false);
    setFormData({
      fullName: null,
      mobileNumber: null,
      userName: null,
      userId: null,
      countryCode: {
        countryCodeId: "249",
      },
    });
  };

  return (
    <div className="mainTeamManagementContainer">
      <div className="teamManagementBreadCrumbDiv">
        <div className="icon">
          <img src={teamManagement} alt="teamicon" />
        </div>
        <ComponentAndScreenConstant.Breadcrumbs
          currentPage={["Team Management"]}
        />
      </div>
      {/* <div className="tableheader">
        <div className="searchbar">
          <input
            type="text"
            placeholder="search"
            value={handleSearchData}
            onChange={(e) => setHandleSearchData(e.target.value)}
          />
        </div>
      </div> */}
      <div className="TeamManagementtableDiv">
        <CommonTable
          data={tableData}
          columns={tableColumns}
          handleSearchData={handleSearchData}
          buttons={
            <div className="addbtn">
              <button className="addpc" onClick={handleAddPlacementCoordinator}>
                + Add Placement Coordinator
              </button>
            </div>
          }
        />
      </div>

      <ComponentAndScreenConstant.CommonModal
        isOpen={openModal}
        onRequestClose={false}
        className="addplacementcoordinator"
      >
        <div className="placementcoordinator">
          <div className="closeicon">
            <button
              type="button"
              className="closepopupbtn"
              onClick={closeAddPlacementCoordinator}
            >
              <img src={closePopup} alt="closeicon" />
            </button>
          </div>
          <div className="modalheading">
            <span>
              {!updatePopup
                ? "Add Placement Coordinator"
                : "Update Placement Coordinator"}
            </span>
          </div>

          <div className="formwrapper">
            <div className="firstrow">
              <div className="placementcordname">
                <ComponentAndScreenConstant.InputText
                  labelTxt="Placement Coordinator Name"
                  inputType="text"
                  inputId="fullName"
                  inputClass="fullNameInput"
                  inputStyle={{ borderRadius: "5px" }}
                  inputValue={formData?.fullName?.fieldValue}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      fullName: onlyAlphabets("Full Name", e.target.value),
                    });
                  }}
                  inputWrapperStyle="fullNameWrapper"
                  errMsg={formData?.fullName?.errorField}
                  placeholderTxt="Enter Placement Coordinator Name"
                  isRequired={true}
                  maxLength={100}
                />
              </div>
              <div className="mobile">
                <ComponentAndScreenConstant.MobileInput
                  labelTxt="Mobile No"
                  inputType="text"
                  inputId="mobile"
                  inputClass="fullNameInput"
                  inputStyle={{ borderRadius: "5px" }}
                  inputValue={formData?.mobileNumber?.fieldValue}
                  selectedValue={formData?.countryCode?.countryCodeId}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mobileNumber: mobileNumberValidations(
                        "Mobile Number",
                        e.target.value,
                        10
                      ),
                    });
                  }}
                  onSelect={(e) => {
                    setFormData({
                      ...formData,
                      countryCode: { countryCodeId: e.target.value },
                    });
                  }}
                  inputWrapperStyle="fullNameWrapper"
                  errMsg={formData?.mobileNumber?.errorField}
                  placeholderTxt="Enter Mobile Number"
                  isRequired={true}
                  maxLength={10}
                />
              </div>
            </div>

            <div className="secondrow">
              <div className="email">
                <ComponentAndScreenConstant.InputText
                  labelTxt="Email ID"
                  inputType="text"
                  inputId="email"
                  inputClass="fullNameInput"
                  inputStyle={{ borderRadius: "5px" }}
                  inputValue={formData?.userName?.fieldValue}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      userName: emailValidationForJobPoster(
                        "Email Address",
                        e.target.value
                      ),
                    })
                  }
                  inputWrapperStyle="fullNameWrapper"
                  errMsg={formData?.userName?.errorField}
                  placeholderTxt="Enter Email ID"
                  isRequired={true}
                  maxLength={50}
                />
              </div>

              <div className="email" style={{ opacity: 0 }}>
                <ComponentAndScreenConstant.InputText
                  labelTxt="Email"
                  inputType=""
                  inputId=""
                  inputClass="fullNameInput"
                  inputStyle={{ borderRadius: "5px" }}
                  inputValue={""}
                  inputWrapperStyle="fullNameWrapper"
                  errMsg={""}
                  placeholderTxt=""
                />
              </div>
            </div>

            <div className="actions">
              <div className="buttonswrapper">
                <button
                  type="button"
                  className="cancel"
                  onClick={closeAddPlacementCoordinator}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="add"
                  onClick={
                    !updatePopup
                      ? addPlaementCoordinatorData
                      : updatePlacementCoordinator
                  }
                >
                  {!updatePopup ? "Add" : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ComponentAndScreenConstant.CommonModal>

      <ComponentAndScreenConstant.AcceptRejectModal
        isOpen={deleteModal}
        message={"Do you want to delete this placement co-ordinator?"}
        onRequestClose={() => {
          setDeleteModal(false);
          setDeleteData(null);
        }}
        onCancel={() => {
          setDeleteModal(false);
          setDeleteData(null);
        }}
        onSelect={() => {
          deleteRecord();
        }}
      />
    </div>
  );
};

export default TeamManagement;
