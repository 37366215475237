import ApplicationStatusBar from "../Components/ApplicationStatus/ApplicationStatus";
import Breadcrumbs from "../Components/BreadCrumb/BreadCrumb";
import CommonModal from "../Components/CommonModal";
import DatePickerField from "../Components/DatePickerField/DatePickerField";
import Header from "../Components/Header/Header";
import HeroSection from "../Components/HeroSection/HeroSection";
import InputText from "../Components/InputText/InputText";
import LoaderModal from "../Components/LoaderModal/LoaderModal";
import SelectField from "../Components/SelectField/SelectField";
import Sidebar from "../Components/Sidebar/Sidebar";
import SuggestedJobsPref from "../Components/SuggestedJobsPref/SuggestedJobsPref";
import jobCard from "../Components/jobCard/jobCard";
import CampusAdminDashboard from "../Screens/CampusAdminDashboard/CampusAdminDashboard";
import JobDashboard from "../Screens/JobDashboard/JobDashboard";
import InstituteInfo from "../Screens/InstituteInfo/InstituteInfo";
import JobDetails from "../Screens/JobDetails/JobDetails";
import Login from "../Screens/Login/Login";
import Notification from "../Screens/Notification/Notification";
import RecruiterInfo from "../Screens/Recruiter/RecruiterInfo";
import Register from "../Screens/Register/Register";
import StudentRepository from "../Screens/StudentRepository/StudentRepository";
import TeamManagement from "../Screens/TeamManagement/TeamManagement";
import UserProfile from "../Screens/profile/UserProfile";
import ApplicantDetailsCard from "../Components/ApplicantDetailsCard/ApplicantDetailsCard";
import JobStatusDetails from "../Screens/JobStatusDetails/JobStatusDetails";
import TextArea from "../Components/TextArea/TextArea";
import AcceptRejectModal from "../Components/AcceptRejectModal/AcceptRejectModal";
import ErrorModal from './../Components/ErrorModal/ErrorModal';
import RegisteredStudents from "../Screens/RegisteredStudents/RegisteredStudents.jsx";
import QuickHireJobs from "../Screens/QuickHireJobs360/QuickHireJobs.jsx";
import Insights from "../Screens/Insights/Insights.jsx";
import OverAllJobPostings from "../Screens/Insights/overalljobpostings/OverAllJobPostings.jsx";
import ActivitySnapShot from "../Screens/Insights/activitysnapshots/ActivitySnapShot.jsx";
import MobileInput from "../Components/countrycode/MobileInput.jsx";
import QuikHireCountShow from "../Components/QuikhireCountShow/QuikhireCountShow.jsx";



export const ComponentAndScreenConstant = {
    // Screens
    Login,
    Register,
    UserProfile,
    Notification,
    JobDashboard,
    RecruiterInfo,
    InstituteInfo,
    TeamManagement,
    JobStatusDetails,
    StudentRepository,
    CampusAdminDashboard,
    RegisteredStudents,
    QuickHireJobs,
    Insights,
    OverAllJobPostings,
    ActivitySnapShot,
    QuikHireCountShow,
    // Components  
    Header,
    Sidebar,
    jobCard,
    TextArea,
    InputText,
    JobDetails,
    Breadcrumbs,
    CommonModal,
    LoaderModal,
    SelectField,
    HeroSection,
    DatePickerField,
    SuggestedJobsPref,
    ApplicationStatusBar,
    ApplicantDetailsCard,
    AcceptRejectModal,
    ErrorModal,
    MobileInput,
}