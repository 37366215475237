import "./RegisteredStudents.css";
import { Url } from "../../EnvoirnmentSetup/APIs";
import React, { useState, useEffect } from "react";
import CommonTable from "../../Components/table/CommonTable";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import {
  ApiCall,
  deleteAPICall,
  getAPICall,
} from "../../APIMethods/APIMethods";
import delIcon from "../../assets/deleteicon.svg";
import editIcon from "../../assets/editicon.svg";
import closePopup from "../../assets/closepopup.svg";
import latestJobIcon from "../../assets/latestJobIcon.svg";
import {
  field,
  onlyAlphabets,
  onlyNumber,
  onEmailChange,
  onStudentEmailChange,
} from "../../Validation/Validation";
import { useSelectedCardContext } from "../../Context/Context";
import { FaPlus, FaDownload } from "react-icons/fa";
import { PiClockCountdownFill } from "react-icons/pi";
import SampleStudentData from "../../assets/SampleStudentData.xlsx";
import whatsappIcon from '../../assets/whatsapp.svg'

const RegisteredStudents = () => {
  const { setIsLoading, setWarning, setAlert } = useSelectedCardContext();

  const [tableData, setTableData] = useState([]);
  const tableColumns = [
    {
      Header: "Sr. No",
      accessor: "srno",
    },
    {
      Header: "Student Name",
      accessor: "fullName",
      Cell: ({ row }) => {
        return (
          <div
          style={{cursor:row?.original?.linkedinProfile ? "pointer" : "default" , color:"#3F4ADE"}}
            onClick={() => {
              window.open(row?.original?.linkedinProfile);
            }}
          >
            <span>{row?.original?.fullName}</span>
          </div>
        );
      },
    },
    {
      Header: "Keywords",
      accessor: "",
      Cell: ({ row }) =>
        Array.isArray(row?.original?.skill) && row.original.skill.length > 0 ? (
          <span>
            {row.original.skill.map((item, ind) => {
              return (
                item.name +
                (ind === row.original.skill.length - 1 ? ". " : ", ")
              );
            })}
          </span>
        ) : (
          <span>NA</span>
        ),
    },
    {
      Header: "Experience",
      accessor: "experience.experience",
    },
    {
      Header: "Location",
      accessor: "",
      Cell: ({ row }) =>
        Array.isArray(row?.original?.state) && row.original.state.length > 0 ? (
          <span>
            {row.original.state.map((item, ind) => {
              return (
                item.stateName +
                (ind === row.original.state.length - 1 ? "" : ", ")
              );
            })}
          </span>
        ) : (
          <span>NA</span>
        ),
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
      Cell: ({ row }) => (
        <div>
          {` +${row?.original?.countryCode?.countryCode}  ${row?.original?.mobileNumber}`}
        </div>
      ),
    },
    {
      Header: "Email ID",
      accessor: "userName",
    },
    {
      Header: "Chat",
      accessor: "",
      Cell: ({ row }) => (
        <img
          src={whatsappIcon}
          className="chatIcon"
          alt="wpicon"
          onClick={() => {
            window.open(
              "https://wa.me/" +
                row?.original?.countryCode?.countryCode +
                row?.original?.mobileNumber
            );
          }}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    // {
    //   Header: "Status",
    //   accessor: "",
    //   Cell: ({ row }) => (
    //     <span
    //       style={{
    //         color: row.original?.linkedinProfile ? "#01774A" : "#EA6200",
    //         fontWeight: 500,
    //       }}
    //     >
    //       {row.original?.linkedinProfile ? "Complete" : "Pending"}
    //     </span>
    //   ),
    // },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div
          className="actionitems"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <img src={viewIcon} alt="" onClick={() => { }} /> */}
          {/* {row.original?.linkedinProfile ? (
            <></>
          ) : (
            <img
              src={editIcon}
              alt=""
              onClick={() => {
                handleOpenStudentModal();
                setStudentData(row.original);
              }}
            />
          )} */}
          <img
            src={delIcon}
            alt=""
            onClick={() => {
              setDeleteModal(true);
              setDeleteData(row.original);
            }}
          />
        </div>
      ),
    },
  ];
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: field,
    mobileNumber: field,
    userName: field,
  });

  const [UploadingXL, setUPloadingXL] = useState(false);
  const [XLData, setXLData] = useState(null);
  const [CountStatus, setCountStatus] = useState();
  const [DeleteData, setDeleteData] = useState();
  const [DeleteModal, setDeleteModal] = useState(false);
  const [handleSearchData, setHandleSearchData] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [excelErrorData, setExcelErrorData] = useState({
    skippedMailUsers: [],
    skippedMobileUsers: [],
  });

  const downloadFile = () => {
    const a = document.createElement("a");
    a.href = SampleStudentData;
    const instituteData = JSON.parse(
      sessionStorage.getItem("instituteDetails")
    );
    a.download = `${instituteData.instituteName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  useEffect(() => {
    getStudentDetails();
  }, []);

  const handleClosePopup = () => {
    setOpenErrorModal(false);
  };
  // ---------------------------------------- API Integration -------------------------------------------------
  const getStudentDetails = async () => {
    setIsLoading(true);
    ApiCall(Url?.getStudentList, "GET", true)
      .then((response) => {
        setIsLoading(false);
        if (response.SUCCESS) {
          console.log("response?.DATA",response?.DATA);
          let filteredData = response?.DATA?.filter((item,i)=>{
             return item.status == "ACTIVE"
          })
          let pendingFilteredData = response?.DATA?.filter((item,i)=>{
            return item.status == "PENDING"
         })

          setTableData(
            filteredData?.map((value, index) => ({
              ...value,
              srno: index + 1,
            }))
          );
          let totalCount = response?.DATA?.length;
          let completeCount = filteredData?.filter(
            (val) => val?.linkedinProfile
          ).length;
          let pendingCount = pendingFilteredData?.length;
          setCountStatus({
            totalCount,
            completeCount,
            pendingCount,
          });
        } else {
          console.log(response.message);
        }
      })
      .catch((err) => console.log(err));
  };

  // ------------------------------------- function calls ---------------------------------------------------------
  const handleOpenStudentModal = () => {
    setOpenModal(true);
  };

  const closeStudentModal = () => {
    setOpenModal(false);
    setStudentData();
    setUPloadingXL(false);
  };

  const setStudentData = (data) => {
    setFormData({
      ...data,
      fullName: { ...field, fieldValue: data?.fullName ?? "" },
      mobileNumber: { ...field, fieldValue: data?.mobileNumber ?? "" },
      userName: { ...field, fieldValue: data?.userName ?? "" },
    });
  };


  // ------------------  Add or Update student --------------

  const addOrUpdateStudent = () => {
    if (!formData.fullName?.fieldValue?.trim() || !formData.fullName?.isValidField) {
      setWarning("Student Name is required.");
    } else if (!formData.mobileNumber?.fieldValue?.trim()) {
      setWarning("Student mobile number is required.");
    } else if (
      formData.mobileNumber?.fieldValue?.trim().length < 4 ||
      formData.mobileNumber?.fieldValue?.trim().length > 10
    ) {
      setWarning("Student mobile number must be between 4 and 10 characters.");
    } else if (formData?.userName?.isValidField === false) {
      setWarning("A valid Student email is required.");
    } else if(!formData.userName?.fieldValue){
      setWarning("The email address should not be empty.");
    }else {
      const body = {
        // ...formData,
        web: true,
        userId: formData.userId,
        fullName: formData.fullName?.fieldValue?.trim(),
        mobileNumber: formData.mobileNumber?.fieldValue?.trim(),
        userName: formData.userName?.fieldValue?.trim(),
        countryCode: {
          countryCodeId: "249",
        },
        roles: [
          {
            name: "ROLE_APPLICANT",
          },
        ],
      };
    
      setIsLoading(true);
      ApiCall(
        Url.managerUserAdd,
        formData?.userId ? "PUT" : "POST",
        true,
        "",
        body
      )
        .then((res) => {
          setIsLoading(false);
          if (res.SUCCESS) {
            closeStudentModal();
            getStudentDetails();
            setAlert(
              `Student ${formData?.userId ? "updated" : "added"} successfully!`
            );
          } else {
            setWarning(res.message);
          }
        })
        .catch((err) => console.log(err));
    }
    




  };

  // --------------------------------------------------- 

  const UnladeXLDoc = () => {
    // return closeStudentModal();
    if (!XLData?.name) {
      setWarning("Select XL to Add Students");
      return;
    }
    const body = new FormData();
    body.append("user-file", XLData);

    setIsLoading(true);
    ApiCall(Url.uploadXLStudent, "POST", true, "", body, true)
      .then((res) => {
        setIsLoading(false);
        if (res.SUCCESS) {
          closeStudentModal();
          getStudentDetails();
          setAlert("Student's Added Successfully!");

          if (res?.skippedMailUsers || res?.skippedMobileUsers) {
            setOpenErrorModal(true);
            setExcelErrorData({
              skippedMailUsers: res?.skippedMobileUsers,
              skippedMobileUsers: res?.skippedMailUsers,
            });
          }
        } else {
          setWarning(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const DeleteStudent = () => {
    if (DeleteData) {
      const userBody = {
        messageAuditId: 0,
        messageOTP: 1111,
        mobileNumber: DeleteData?.mobileNumber,
        countryCode: DeleteData?.countryCode,
      };
      setIsLoading(true);
      ApiCall(Url.hardDeleteUser, "DELETE", true, "", userBody)
        .then((res) => {
          setIsLoading(false);
          if (res.SUCCESS) {
            getStudentDetails();
            setAlert("Student deleted successfully!");

            setDeleteModal(false);
            setDeleteData(null);
          } else {
            setWarning(res.message);
            setDeleteModal(false)
          }
        })
        .catch((err) => console.log(err));
    }
  };
  // ------------------------------------- function calls ---------------------------------------------------------
  const handleSendReminder = () => {
    if (tableData.length > 0) {
      setIsLoading(true);
      getAPICall(Url.sendReminderForAll).then((res) => {
        setIsLoading(false);
        if (res.SUCCESS) {
          setAlert("The reminder was successfully sent to everyone!");
        } else {
          setWarning("Somthing Went Wrong.");
        }
      });
    } else {
      setWarning("Student details not available.");
    }
  };

  return (
    <div className="mainRegisteredStudentsContainer">
      <div className="StudentHeaderText">
        <div className="imageAndText">
          <img src={latestJobIcon} alt="" />
          <span>Registered Student</span>
        </div>
        <div className="studentCountContainerDiv">
          <div className="studentCountDiv" style={{ color: "#000" }}>
            <span style={{ borderColor: "#000" }}>
              {CountStatus?.totalCount
                ? CountStatus?.totalCount < 10
                  ? "0" + CountStatus?.totalCount
                  : CountStatus?.totalCount
                : "00"}
            </span>
            No. of Students
          </div>
          <div className="studentCountDiv" style={{ color: "#01774A" }}>
            <span style={{ borderColor: "#01774A" }}>
              {CountStatus?.completeCount
                ? CountStatus?.completeCount < 10
                  ? "0" + CountStatus?.completeCount
                  : CountStatus?.completeCount
                : "00"}
            </span>
            Register Students
          </div>
          <div className="studentCountDiv" style={{ color: "#EA6200" }}>
            <span style={{ borderColor: "#EA6200" }}>
              {CountStatus?.pendingCount
                ? CountStatus?.pendingCount < 10
                  ? "0" + CountStatus?.pendingCount
                  : CountStatus?.pendingCount
                : "00"}
            </span>
            Pending Students
          </div>
        </div>
      </div>
      <div className="tableHeader">
        {/* <div className="searchbar">
          <input
            type="text"
            placeholder="search"
            onChange={(e) => setHandleSearchData(e.target.value)}
          />
        </div> */}
        {/* <div className="sortdata">
          <button type="button">
            Sort By : <span>A to Z</span>
            <span></span>
          </button>
        </div> */}
      </div>
      <div className="RegisteredStudentstableDiv">
        <CommonTable
          data={tableData}
          columns={tableColumns}
          handleSearchData={handleSearchData}
          // buttons={
          //   <>
          //     <button
          //       className="addStudentButton"
          //       onClick={handleOpenStudentModal}
          //     >
          //       <FaPlus />
          //       Add Student
          //     </button>
          //     <button
          //       className="studentReminderButton"
          //       onClick={() => {
          //         handleSendReminder();
          //       }}
          //     >
          //       <PiClockCountdownFill size={"1.5rem"} />
          //       Reminder Send to Pending's
          //     </button>
          //   </>
          // }
        />
      </div>

      <ComponentAndScreenConstant.CommonModal
        isOpen={openModal}
        // onRequestClose={closeStudentModal}
        className="addStudentModal"
      >
        <img
          src={closePopup}
          alt="closeicon"
          className="closeIcon"
          onClick={closeStudentModal}
        />
        <h2>{!formData?.userId ? "Add Student" : "Update Student"}</h2>
        {!formData?.userId && (
          <div className="toggleContainer">
            <button
              className={`toggleButton ${UploadingXL ? "" : "activeToggle"}`}
              onClick={() => {
                setUPloadingXL(false);
              }}
            >
              Single Student
            </button>
            <button
              className={`toggleButton ${!UploadingXL ? "" : "activeToggle"}`}
              onClick={() => {
                setUPloadingXL(true);
              }}
            >
              Multiple Student
            </button>
          </div>
        )}
        {UploadingXL ? (
          <div className="filePickerDiv">
            <div className="fileInputDiv" style={{ height: "125px" }}>
              <button
                onClick={() => {
                  downloadFile();
                }}
                className="downloadSample"
              >
                <FaDownload /> Download Sample Excel
              </button>
              <input
                type="file"
                // accept='image/*'
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xl, .xls, .xlsx"
                onChange={(e) => {
                  console.log(e.target.files[0]);
                  setXLData(e.target.files[0]);
                }}
              />
              <p>{XLData?.name ?? "Select Student Excel Data"}</p>
            </div>
          </div>
        ) : (
          <div className="instituteFormDiv gap-10">
            <div className="formFlexRowDiv">
              <ComponentAndScreenConstant.InputText
                labelTxt="Student Name"
                inputType="text"
                inputId="fullName"
                inputValue={formData?.fullName?.fieldValue}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    fullName: onlyAlphabets("Full Name", e.target.value),
                  })
                }
                inputWrapperStyle="fullNameWrapper"
                errMsg={formData?.fullName?.errorField}
                placeholderTxt="Enter Student Name"
                isRequired={true}
                maxLength={100}
              />
              <ComponentAndScreenConstant.InputText
                labelTxt="Mobile No"
                inputType="text"
                inputId="mobile"
                inputValue={formData?.mobileNumber?.fieldValue}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    mobileNumber: onlyNumber(
                      "Mobile Number",
                      e.target.value,
                      10
                    ),
                  });
                }}
                errMsg={formData?.mobileNumber?.errorField}
                placeholderTxt="Enter Mobile Number"
                isRequired={true}
                maxLength={10}
              />
            </div>
            <div className="formFlexRowDiv">
              <ComponentAndScreenConstant.InputText
                labelTxt="Email ID"
                inputType="text"
                inputId="email"
                inputClass="fullNameInput"
                inputValue={formData?.userName?.fieldValue}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    userName: onStudentEmailChange(
                      "Email Address",
                      e.target.value
                    ),
                  })
                }
                inputWrapperStyle="fullNameWrapper"
                errMsg={formData?.userName?.errorField}
                placeholderTxt="Enter Email ID"
                isRequired={true}
                maxLength={50}
              />
              <div style={{ width: "100%" }} />
            </div>
          </div>
        )}
        <div className="buttonDiv">
          <button onClick={closeStudentModal}>Cancel</button>
          <button
            style={{ backgroundColor: "#01774A", color: "#fff" }}
            onClick={UploadingXL ? UnladeXLDoc : addOrUpdateStudent}
          >
            {!formData?.userId ? "Add" : "Update"}
          </button>
        </div>
      </ComponentAndScreenConstant.CommonModal>
      <ComponentAndScreenConstant.AcceptRejectModal
        isOpen={DeleteModal}
        message={"Do you want to delete this student?"}
        onRequestClose={() => {
          setDeleteModal(false);
          setDeleteData(null);
        }}
        onCancel={() => {
          setDeleteModal(false);
          setDeleteData(null);
        }}
        onSelect={() => {
          DeleteStudent();
        }}
      />

      <ComponentAndScreenConstant.ErrorModal
        isOpen={openErrorModal}
        handleClosePopup={handleClosePopup}
        excelErrorData={excelErrorData}
      />
    </div>
  );
};

export default RegisteredStudents;
