import React , {useState , useEffect} from "react";
import './MobileInput.css'
import { getAPICall } from './../../APIMethods/APIMethods';
import { Url } from "../../EnvoirnmentSetup/APIs";

const MobileInput = ({
  labelTxt,
  inputType,
  inputClass,
  inputValue,
  onChange,
  inputStyle,
  inputWrapperStyle,
  readOnly,
  errMsg,
  inputId,
  placeholderTxt,
  isRequired = false,
  maxLength = 100,
  onSelect,
  selectedValue,
}) => {

    const [countryCodes, setCountryCodes] = useState([])

    useEffect(() => {
        getAPICall(Url.CountryCodes)
        .then((res) => {
          setCountryCodes(res?.DATA)
        })
        .catch((e) => {
          console.log(e);
        })
    }, [])
    
  return (
    <div className="mobileinputwrapper" style={{marginBottom:'10px'}}>
        <label htmlFor="" className="moblabel">Mobile No <span style={{ color: "#BC0000", fontSize: "14px" }}>*</span></label>
      <div className="inputs" style={{display:'flex' , justifyContent:"flex-start" , alignItems:"center"}}>
        <select disabled onChange={onSelect} className="countryselect" value={selectedValue}>
          {countryCodes.map((item) => {
            return (
              <option value={item?.countryCodeId}>+{item?.countryCode}</option>
            );
          })}
        </select>
        <input
          type={inputType}
          id={inputId}
          maxLength={inputType == "number" ? 10 : maxLength}
          readOnly={readOnly}
          className={`mobInput ${inputClass}`}
          placeholder={placeholderTxt}
          style={inputStyle}
          value={inputValue}
          onChange={onChange}
        ></input>
       
      </div>
      {!!errMsg && <small className="errorMessageShow">{errMsg}</small>}
    </div>
  );
};

export default MobileInput;
