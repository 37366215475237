import React, { useState, useEffect } from "react";
import { Url } from "../../EnvoirnmentSetup/APIs";
import CommonTable from "../../Components/table/CommonTable";
import latestJobIcon from "../../assets/latestJobIcon.svg";

const QuickHireJobs = () => {
  return (
    <div className="quickhirejobswrapper">
         <div className="StudentHeaderText">
        <div className="imageAndText">
          <img src={latestJobIcon} alt="" />
          <span>Quikhire Jobs</span>
        </div>
      </div>
    </div>
  )
}

export default QuickHireJobs