import React from "react";
import "./InstituteInfo.css";
import latestJobIcon from "../../assets/latestJobIcon.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { ApiCall, getAPICall } from "../../APIMethods/APIMethods";
import { PortalUserRole, Url } from "../../EnvoirnmentSetup/APIs";
import closePopup from "../../assets/closepopup.svg";
import {
  allowAllCharacters,
  emailValidationForJobPoster,
  field,
  onlyAlphabetsWithRoundBracketComma,
  validateAndFormatURL,
  mobileNumberValidations,
} from "../../Validation/Validation";
import { useSelectedCardContext } from "../../Context/Context";
import instituteIcon from "../../assets/companyPostedIcon.svg";
import { useNavigate } from "react-router-dom";

const InstituteInfo = () => {
  const { setIsLoading, setWarning, setAlert } = useSelectedCardContext();

  const [isAddingInstitute, setIsAddingInstitute] = React.useState(false);
  const [InstituteFormData, setInstituteFormData] = React.useState({
    email: field,
    description: field,
    instituteName: field,
    instituteAddress: field,
    instituteContact: field,
    instituteWebsite: field,
    instituteLogo: null,
    countryCode: {
      countryCodeId: "249",
    },
  });
  const [InstituteData, setInstituteData] = React.useState({});

  React.useEffect(() => {
    getInstituteData();
  }, []);

  const getInstituteData = () => {
    getAPICall(Url.instituteAll)
      .then((res) => {
        if (res?.SUCCESS) {
          sessionStorage.setItem(
            "instituteDetails",
            JSON.stringify(res?.DATA[0])
          );
          setInstituteData(res?.DATA[0]);
        }
        if (res.DATA?.length <= 0) {
          setIsAddingInstitute(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const navigate = useNavigate();
  const uploadLogo = () => {
    if (InstituteFormData?.instituteLogo?.name) {
      const body = new FormData();
      body.append("instituteLogo", InstituteFormData?.instituteLogo);
      setIsLoading(true);
      ApiCall(Url.uploadDoc, "POST", true, "", body, true)
        .then((res) => {
          setIsLoading(false);
          if (res.SUCCESS) {
            AddOrUpdateInstitute(res?.DATA?.INSTITUTE_LOGO);
          } else {
            setWarning(res?.message);
          }
        })
        .catch((e) => console.log(e));
    } else {
      AddOrUpdateInstitute(InstituteFormData?.instituteLogo);
    }
  };

  const AddOrUpdateInstitute = (logo = null) => {
    if (
      !!!InstituteFormData?.instituteName?.fieldValue?.trim() ||
      !InstituteFormData?.instituteName?.isValidField
    ) {
      setWarning("Please enter valid institute name.");
      return;
    } else if (
      !!!InstituteFormData?.instituteContact?.fieldValue?.trim() ||
      !InstituteFormData?.instituteContact?.isValidField
    ) {
      setWarning("Please enter valid contact number.");
      return;
    } else if (InstituteFormData?.instituteContact?.fieldValue.length < 4) {
      setWarning("The entered number is not valid.");
      return;
    } else if (
      !!!InstituteFormData?.email?.fieldValue?.trim() ||
      !InstituteFormData?.email?.isValidField
    ) {
      setWarning("Please enter valid email address.");
      return;
    } else if (
      !!!InstituteFormData?.instituteAddress?.fieldValue?.trim() ||
      !InstituteFormData?.instituteAddress?.isValidField ||
      InstituteFormData?.instituteAddress?.fieldValue.length < 2
    ) {
      setWarning("Please enter valid institute address.");
      return;
    } else if (
      !!!InstituteFormData?.description?.fieldValue?.trim() ||
      !InstituteFormData?.description?.isValidField
    ) {
      setWarning("Please Enter valid Institute Description.");
      return;
    } else if (InstituteFormData?.description?.fieldValue.trim().length < 20) {
      setWarning("Please Enter valid Institute Description.");
      return;
    } else {
      // Validate and format the website URL
      const urlValidation = validateAndFormatURL(
        InstituteFormData?.instituteWebsite?.fieldValue?.trim()
      );

      if (urlValidation.error) {
        setWarning(urlValidation.error);
        return;
      }

      const body = {
        instituteLogo: logo,
        instituteId: InstituteFormData?.instituteId,
        instituteUid: InstituteFormData?.instituteUid,
        email: InstituteFormData?.email?.fieldValue?.trim().toLocaleLowerCase(),
        description: InstituteFormData.description?.fieldValue?.trim(),
        instituteName: InstituteFormData?.instituteName?.fieldValue?.trim(),
        instituteWebsite: urlValidation.formattedURL,
        instituteContact:
          InstituteFormData?.instituteContact?.fieldValue?.trim(),
        instituteAddress:
          InstituteFormData?.instituteAddress?.fieldValue?.trim(),
        countryCode: {
          countryCodeId: InstituteFormData?.countryCode?.countryCodeId,
        },
      };

      setIsLoading(true);
      ApiCall(
        Url.institute,
        InstituteFormData?.instituteId ? "PUT" : "POST",
        true,
        "",
        body
      )
        .then((res) => {
          setIsLoading(false);
          if (res.SUCCESS) {
            getInstituteData();
            onRequestCloseFormApi();
            if (!InstituteFormData?.instituteId) {
              setAlert("Institute Information added successfully!");
              setTimeout(() => {
                navigate("/Main/latestJob");
              }, 5000);
            } else {
              setAlert("Institute Information Updated successfully!");
            }
          } else {
            setWarning(res?.message);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const ResetFormData = (data) => {
    setInstituteFormData({
      ...data,
      email: { ...field, fieldValue: data?.email ?? "" },
      instituteAddress: { ...field, fieldValue: data?.instituteAddress ?? "" },
      description: { ...field, fieldValue: data?.description ?? "" },
      instituteName: { ...field, fieldValue: data?.instituteName ?? "" },
      instituteContact: { ...field, fieldValue: data?.instituteContact ?? "" },
      instituteWebsite: { ...field, fieldValue: data?.instituteWebsite ?? "" },
      instituteLogo: data?.instituteLogo ?? null,
      countryCode: data?.countryCode,
    });
  };

  const onRequestCloseForm = () => {
    ResetFormData();
    setIsAddingInstitute(false);
    if (!InstituteData?.instituteName) {
      sessionStorage.clear();
      navigate("/login");
    }
  };

  const onRequestCloseFormApi = () => {
    ResetFormData();
    setIsAddingInstitute(false);
  };
  return (
    <div className="instituteMainDiv">
      <div className="iconAndTextDiv">
        <img src={latestJobIcon} alt="" />
        <span>Institute Information</span>
      </div>
      <img
        src={require("../../assets/instituteBGImage.png")}
        className="backImage"
        alt=""
      />
      <div className="instituteInformationDiv">
        <div className="headerDivContainer">
          <img
            src={
              !!InstituteData?.instituteLogo
                ? InstituteData?.instituteLogo
                : instituteIcon
            }
            alt=""
            className="instituteLogo"
          />
          <div className="lineDiv" />
          <div className="instituteNameAndAddress">
            <h2>{InstituteData?.instituteName}</h2>
            <p className="instituteContactDetails">
              {" "}
              <span className="instituteDetailsLable">Contact No.</span> :{" "}
              <span className="instituteDetailsValue">
                {InstituteData?.instituteContact}{" "}
              </span>
            </p>
            <p className="instituteContactDetails">
              {" "}
              <span className="instituteDetailsLable">Email ID</span> :{" "}
              <span className="instituteDetailsValue">
                {InstituteData?.email}{" "}
              </span>
            </p>
            <p className="instituteContactDetails">
              {" "}
              <span className="instituteDetailsLable">Address</span> :{" "}
              <span className="instituteDetailsValue">
                {InstituteData?.instituteAddress ?? ""}{" "}
              </span>
            </p>
          </div>
          <span
            className="websiteLink"
            onClick={() =>
              !!InstituteData?.instituteWebsite
                ? window.open(InstituteData?.instituteWebsite, "_blank")
                : null
            }
          >
            Visit Website <FaArrowRightLong />
          </span>
        </div>
        <div className="instituteDecryptionDev">
          <label htmlFor="s">Description:</label>
          <p>{InstituteData?.description}</p>
        </div>
        {PortalUserRole === "ROLE_MANAGER" && (
          <button
            onClick={() => {
              setIsAddingInstitute(true);
              ResetFormData(InstituteData);
            }}
          >
            Edit
          </button>
        )}
      </div>
      <ComponentAndScreenConstant.CommonModal
        isOpen={isAddingInstitute}
        onRequestClose={onRequestCloseForm}
        className={"addInstituteModal"}
      >
        <>
          <img
            src={closePopup}
            alt="closeicon"
            className="closeIcon"
            onClick={onRequestCloseForm}
          />
          <h2>{InstituteFormData?.instituteId ? "Update" : "Add"} Institute</h2>
          <div className="instituteFormDiv gap-10">
            <div className="formFlexRowDiv">
              <div className="filePickerDiv">
                {!InstituteFormData?.instituteLogo ? (
                  <div className="fileInputDiv">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        setInstituteFormData((data) => ({
                          ...data,
                          instituteLogo: e.target.files[0],
                        }));
                      }}
                    />
                    <p>
                      {InstituteFormData?.instituteLogo?.name ??
                        "Select Institute Logo"}
                    </p>
                  </div>
                ) : (
                  <div
                    className="fileInputDiv"
                    style={{
                      backgroundColor: "transparent",
                      justifyContent: "flex-start" /*  border: 'none' */,
                    }}
                  >
                    <img
                      src={
                        InstituteFormData?.instituteLogo?.name
                          ? URL.createObjectURL(
                              InstituteFormData?.instituteLogo
                            )
                          : InstituteFormData?.instituteLogo
                      }
                      className="instituteFormLogo"
                      alt=""
                    />
                    <img
                      alt="closeicon"
                      src={closePopup}
                      className="closeIcon"
                      style={{ height: "16px", width: "16px" }}
                      onClick={() => {
                        setInstituteFormData((data) => ({
                          ...data,
                          instituteLogo: null,
                        }));
                      }}
                    />
                  </div>
                )}
              </div>
              <ComponentAndScreenConstant.InputText
                isRequired={true}
                labelTxt={"Institute Name"}
                placeholderTxt={"Enter Institute Name"}
                errMsg={InstituteFormData?.instituteName?.errorField}
                inputValue={InstituteFormData?.instituteName?.fieldValue}
                onChange={({ target: { value } }) => {
                  setInstituteFormData((data) => ({
                    ...data,
                    instituteName: onlyAlphabetsWithRoundBracketComma(
                      "Institute Name",
                      value
                    ),
                  }));
                }}
              />
            </div>
            <div className="formFlexRowDiv">
              <ComponentAndScreenConstant.MobileInput
                labelTxt="Mobile No"
                inputType="text"
                inputId="mobile"
                inputClass="fullNameInput"
                inputStyle={{ borderRadius: "5px" }}
                inputValue={InstituteFormData?.instituteContact?.fieldValue}
                selectedValue={InstituteFormData?.countryCode?.countryCodeId}
                onChange={({ target: { value } }) => {
                  if (value.length <= 10)
                    setInstituteFormData((data) => ({
                      ...data,
                      instituteContact: mobileNumberValidations(
                        "Contact No",
                        value
                      ),
                    }));
                }}
                onSelect={(e) => {
                  setInstituteFormData({
                    ...InstituteFormData,
                    countryCode: { countryCodeId: e.target.value },
                  });
                }}
                inputWrapperStyle="fullNameWrapper"
                errMsg={InstituteFormData?.instituteContact?.errorField}
                placeholderTxt="Enter Mobile Number"
                isRequired={true}
                maxLength={10}
              />
              <ComponentAndScreenConstant.InputText
                labelTxt={"Email ID"}
                // inputValue={''}
                isRequired={true}
                placeholderTxt={"Enter Email ID"}
                errMsg={InstituteFormData?.email?.errorField}
                inputValue={InstituteFormData?.email?.fieldValue}
                onChange={({ target: { value } }) => {
                  setInstituteFormData((data) => ({
                    ...data,
                    email: emailValidationForJobPoster("Email address", value),
                  }));
                }}
              />
            </div>
            <div className="formFlexRowDiv">
              <ComponentAndScreenConstant.InputText
                labelTxt={"Institute address"}
                // inputValue={''}
                isRequired={true}
                placeholderTxt={"Enter Institute address"}
                errMsg={InstituteFormData?.instituteAddress?.errorField}
                inputValue={InstituteFormData?.instituteAddress?.fieldValue}
                onChange={({ target: { value } }) => {
                  setInstituteFormData((data) => ({
                    ...data,
                    instituteAddress: allowAllCharacters(
                      "Institute address",
                      value
                    ),
                  }));
                }}
              />
              {/* <ComponentAndScreenConstant.InputText
                labelTxt={"Institute Website Link"}
                // inputValue={''}
                isRequired={true}
                placeholderTxt={"Enter Website Link"}
                errMsg={InstituteFormData?.instituteWebsite?.errorField}
                inputValue={InstituteFormData?.instituteWebsite?.fieldValue}
                onChange={({ target: { value } }) => {
                  setInstituteFormData((data) => ({
                    ...data,
                    instituteWebsite: allowAllCharacters("Website Link", value),
                  }));
                }}
              /> */}
              <ComponentAndScreenConstant.InputText
                labelTxt={"Institute Website Link"}
                isRequired={true}
                placeholderTxt={"Enter Website Link"}
                errMsg={InstituteFormData?.instituteWebsite?.errorField}
                inputValue={InstituteFormData?.instituteWebsite?.fieldValue}
                onChange={({ target: { value } }) => {
                  const { formattedURL, error } = validateAndFormatURL(value);

                  setInstituteFormData((data) => ({
                    ...data,
                    instituteWebsite: {
                      fieldValue: formattedURL,
                      errorField: error,
                    },
                  }));
                }}
              />
            </div>
            <div className="instituteDescriptionDiv">
              <label>
                Institute Description <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                placeholder="Institute Description"
                rows={6}
                value={InstituteFormData?.description?.fieldValue}
                onChange={({ target: { value } }) => {
                  setInstituteFormData((data) => ({
                    ...data,
                    description: allowAllCharacters(
                      "institute description",
                      value
                    ),
                  }));
                }}
                maxLength={5000}
              />
              <div className="errmsgField">
                <small style={{ color: "red" }}>
                  {InstituteFormData?.description?.errorField}
                </small>
                <small style={{ color: "#a7a7a7", textAlign: "right" }}>
                  {InstituteFormData?.description?.fieldValue?.length ?? 0}/
                  {5000}
                </small>
              </div>
            </div>
          </div>
          <div className="buttonDiv">
            <button onClick={onRequestCloseForm}>Cancel</button>
            <button
              onClick={() => uploadLogo()}
              style={{ backgroundColor: "#01774A", color: "#fff" }}
            >
              {InstituteFormData?.instituteId ? "Update" : "Add"}
            </button>
          </div>
        </>
      </ComponentAndScreenConstant.CommonModal>
    </div>
  );
};

export default InstituteInfo;
