export const ProfileConstant = {
    DevRecruiterV1 :'DevRecruiterV1',
    IntRecruiterV1 :'IntRecruiterV1' ,
    UatRecruiterV1 :'UatRecruiterV1',
    DevLeaderV1 :'DevLeaderV1',
    IntLeaderV1 :'IntLeaderV1' ,
    UatLeaderV1 :'UatLeaderV1',
    ProdRecruiterV1:'ProdRecruiterV1',
    ProdLeaderV1:'ProdLeaderV1',


    DevApplicantV1 :'DevApplicantV1',
    IntApplicantV1 :'IntApplicantV1' ,
    UatApplicantV1 :'UatApplicantV1',
}

export const RoleConstant = {
    ROLE_MANAGER :'ROLE_MANAGER',
    ROLE_RECRUTER :'ROLE_RECRUTER' ,
    ROLE_ADMIN :'ROLE_ADMIN' ,
    ROLE_APPLICANT :'ROLE_APPLICANT' ,

}