import React, { useEffect, useState } from "react";
import "./QuikhireCountShow.css";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import CommonTable from "../../Components/table/CommonTable";
import { Url } from "../../EnvoirnmentSetup/APIs";
import { getAPICall } from "../../APIMethods/APIMethods";
import { useSelectedCardContext } from "../../Context/Context"; 
import quikhireCountShow from "../../assets/teamgementheader.svg";

const QuikHireCountShow = () => {
  const [tableData, setTableData] = useState([]);
  const tableColumns = [
    {
      Header: "Sr. No",
      accessor: "srno",
    },
    {
      Header: "Student Name",
      accessor: "studentName",
    },
    {
      Header: "Internship",
      accessor: "viewedInternJobs",
    },
    {
      Header: "Fresher",
      accessor: "viewedFresherJobs",
    },
    
  ];

  const [handleSearchData] = useState("");
  const { setIsLoading } = useSelectedCardContext();

  // -------------------------------------------------- API Integration ---------------------------------------------

  const fetchTableData = async () => {
    try {
      setIsLoading(true);
      const tableData = await getAPICall(Url?.quikhireJobCount); 
      if (tableData.SUCCESS) {
        setIsLoading(false);
        const newArr = tableData?.DATA?.filter(item => 
          item?.performaceCounts?.viewedInternJobs !== 0 || item?.performaceCounts?.viewedFresherJobs !== 0
      )?.map((item, i) => {
          return {
              srno: i + 1, 
              studentName : item?.appUser?.fullName,
              viewedInternJobs: item?.performaceCounts?.viewedInternJobs,
              viewedFresherJobs: item?.performaceCounts?.viewedFresherJobs
          };
      });
      
      setTableData(newArr);
      
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);


  return (
    <div className="mainquikhireCountShowContainer">
      <div className="quikhireCountShowBreadCrumbDiv">
        <div className="icon">
          <img src={quikhireCountShow} alt="teamicon" />
        </div>
        <ComponentAndScreenConstant.Breadcrumbs
          currentPage={["QuikHire Visits"]}
        />
      </div>

      <div className="quikhireCountShowtableDiv">
        <CommonTable
          data={tableData}
          columns={tableColumns}
        />
      </div>
    </div>
  );
};

export default QuikHireCountShow;
