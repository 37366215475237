import React, { useContext } from "react";
import { createContext } from "react";

const AuthContext = createContext();
const SelectedCardContext = createContext();
const NotificationContext = createContext();

const useAuthContext = () => {
    return useContext(AuthContext)
}

const useSelectedCardContext = () => {
    return useContext(SelectedCardContext)
}

const useNotificationContext = () => {
    return useContext(NotificationContext)
}



export {AuthContext, useAuthContext, SelectedCardContext, useSelectedCardContext, NotificationContext, useNotificationContext}