import React, { useEffect, useRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import calenderIcon from '../../assets/GroupCalender.svg'
import './DatePickerField.css'
import moment from 'moment';

const DatePickerField = ({
    labelTxt,
    inputWrapperStyle,
    inputWrapperClassName,
    errMsg,
    inputClass,
    onChange,
    minDate,
    maxDate,
    inputId,
    selectedValue, dateFormat,
    showYearPicker,
    disabledData,
    showMonthYearPicker,
    placeholderText,
    isRequired = false
}) => {
    const datePickerRef = useRef(null);

    const handleIconClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true);
        }
    };

    useEffect(() => {
        if (datePickerRef.current) {
        }
    }, [datePickerRef.current]);

    const formatDate = (timeString, formate) => {
        //formate eg. "MM/DD/YYYY HH:mm A" etc
        const parsedDateTime = moment(timeString);
        const newTimeString = parsedDateTime.local();
        let str = newTimeString.format(formate);
        return str;
    }
    return (
        <div className={`${inputWrapperStyle} custom-datepicker-container`} style={{ display: "flex", flexDirection: "column" }}>
            {!!labelTxt && <label className='inputLable' htmlFor={inputId}>{labelTxt}{isRequired && <small style={{ color: "#BC0000" }}>*</small>}</label>}
            <div className='datePickerMainDiv'>
                <ReactDatePicker
                    ref={datePickerRef}
                    onChange={onChange}
                    menuPlacement="auto"
                    disabled={disabledData}
                    dateFormat={'dd-MM-yyyy'}
                    showYearPicker={showYearPicker}
                    showMonthYearPicker={showMonthYearPicker}
                    placeholderText={placeholderText}
                    className={`${inputClass} custom-datepicker`}
                    calendarClassName="custom-datepicker-calendar"
                    onKeyDown={(e) => e.preventDefault()}
                    minDate={minDate ? new Date(formatDate(minDate, 'ddd, MMM DD, YYYY, hh:mm:ss A [GMT]Z')) : null}
                    maxDate={maxDate ? new Date(formatDate(maxDate, 'ddd, MMM DD, YYYY, hh:mm:ss A [GMT]Z')) : null}
                    selected={selectedValue ? new Date(formatDate(selectedValue, 'ddd, MMM DD, YYYY, hh:mm:ss A [GMT]Z')) : null}
                />
                <img style={{ opacity: disabledData && "0.5" }} src={calenderIcon} alt="" onClick={() => { handleIconClick() }} />
            </div>
            {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
        </div>
    )
}

export default DatePickerField