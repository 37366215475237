import './App.css';
import Modal from "react-modal";
import { useState } from 'react';
import Lottie from "lottie-react";
import Oops from "./assets/Opps.json";
import completed from "./assets/Success.json";
import { SelectedCardContext } from './Context/Context';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ComponentAndScreenConstant } from './constants/componentAndScreenConstant';

function App() {
  const [selectedCard, setSelectedCard] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [jobModal,setJobModal]=useState(false)
  const [AlertMessages, setAlertMessages] = useState({
    message: '',
    isWaring: false,
    isVisible: false,
  })
  const [jobCreateDetails, setJobCreateDetails] = useState()

  const setWarning = (message) => {
    setAlertMessages({
      message: message,
      isWaring: true,
      isVisible: true,
    })
  }

  const setAlert = (message) => {
    setAlertMessages({
      message: message,
      isWaring: false,
      isVisible: true,
    })
  }

  const resetAlertMessages = () => {
    setAlertMessages({
      message: '',
      isWaring: false,
      isVisible: false,
    });
  }

  const [filterOnBack , setFilterOnBack ] = useState()
  const [contextJobData , setContextJobData ] = useState()

  return (
    <div className="App">
      <HashRouter>
        <SelectedCardContext.Provider value={{ selectedCard, setSelectedCard, isLoading, setIsLoading, setWarning, setAlert,jobModal,setJobModal,jobCreateDetails, setJobCreateDetails , filterOnBack , setFilterOnBack , contextJobData , setContextJobData }}>
          <Routes>
            <Route path='/' element={<Navigate to='login' />} />
            <Route path='/login' element={<ComponentAndScreenConstant.Login />} />
            <Route path='/register' element={<ComponentAndScreenConstant.Register />} />
            <Route path='/Main/*' element={<ComponentAndScreenConstant.CampusAdminDashboard />} />
            <Route path='/suggestedPref' element={<ComponentAndScreenConstant.SuggestedJobsPref />} />
          </Routes>
        </SelectedCardContext.Provider>
      </HashRouter>
      <Modal
        ariaHideApp={false}
        className={`modalStyle`}
        overlayClassName={'modalOverlayStyle AlertModal'}
        isOpen={AlertMessages.isVisible}
        onRequestClose={resetAlertMessages}
      >
        <div className='warningOrAlert'>
          <Lottie
            loop={true}
            animationData={AlertMessages?.isWaring ? Oops : completed}
            className="delAccModalErrLottie"
          />
          {AlertMessages?.message}
          <button onClick={resetAlertMessages}>OK</button>
        </div>
      </Modal>
      <ComponentAndScreenConstant.LoaderModal setLoader={isLoading} />
    </div>
  );
}

export default App;
