import "./ErrorModal.css";
import Modal from "react-modal";
import closeIcon from "../../assets/closepopup.svg";

const ErrorModal = ({ isOpen, excelErrorData, handleClosePopup }) => {
  let skippedMobiles = excelErrorData?.skippedMobileUsers;
  let skippedMails = excelErrorData?.skippedMailUsers;
  const allData = [...skippedMails, ...skippedMobiles];
  console.log('excelErrorData..................',excelErrorData)
  return (
    <Modal
      className="errormodal"
      isOpen={isOpen}
      overlayClassName="errorModalOverlay"
    >
      <div className="errorwrapper">
        <div className="errorheader">
          <div className="heading">Reminder </div>
          <div className="closeicon">
            <button onClick={handleClosePopup}>
              <img src={closeIcon} alt="closeicon" />
            </button>
          </div>
        </div>

        <div className="errorcontent">
          {allData && (
            <div className="skippedData">
              <div className="errormsg">
                <span>
                {excelErrorData?.DATA?.length ?? 0} students uploaded successfully and {allData?.length ?? 0} skipped due to duplicates/blanks. Review the list below.
                </span>
              </div>
              <div className="errtable">
                <table>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email ID</th>
                      <th>Mobile Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allData?.map((item, i) => {
                      return (
                        <tr key={`mobilerow${i + 1}`}>
                          <td>{item?.fullName}</td>
                          <td>{item?.userName}</td>
                          <td>{item?.mobileNumber}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        <div className="actionbtn">
          <button className="okbtn" onClick={handleClosePopup}>
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
