import { getAPICall } from "../APIMethods/APIMethods";
import { Url } from "../EnvoirnmentSetup/APIs";
// import moment from "moment-timezone";

export const getPremiumMemberUserInfo = async () => {
  let membershipStatus = new Promise(async (resolve, reject) => {
    getAPICall(Url.MemberShipStatus)
      .then((res) => {
        if (res.SUCCESS) {
          if (!!res.DATA) {
            resolve(res.DATA)
          } else {
            resolve(res.DATA)
            alert("Your premium membership is expired")
          }
        }
      }).catch((e) => console.log(e));
  })
  return membershipStatus
}


export const DocumentUploader = (file, appendVariable, folderName) => {
  let fileUrl = new Promise((resolve, reject) => {
    let token = sessionStorage.getItem("token");
    if (file) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token,);
      const formdata = new FormData();
      formdata.append(appendVariable, file);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

      fetch(Url.UploadDocumentToBucket.replace("{folderName}", folderName), requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.SUCCESS) {
            resolve(result?.DATA)
          } else {
            reject()
          }
        })
        .catch((error) => console.error(error));
    }
  })
  return fileUrl

}

export const ExcelDownload = async (api, fileName) => {
  try {
    const response = await fetch(api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Authorization': "Bearer " + sessionStorage.getItem("token"),
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // Get the response as a Blob
    const blob = await response.blob();
    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName ? fileName + '.xlsx' : 'untitled.xlsx'; // Set the desired file name
    document.body.appendChild(a);
    a.click();
    // Clean up
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Download failed', error);
  }
}

export const modifyTimezoneString = (timeString, formate) => {
  //formate eg. "MM/DD/YYYY HH:mm A" etc
  // const parsedDateTime = moment(timeString);
  // const newTimeString = parsedDateTime.local();
  // let str=newTimeString.format(formate);
  // return str;
}


export const handleCopyDetails = (text) => {
  try {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
    document.execCommand('copy');
    document.body.removeChild(textArea);
    

  } catch (err) {
    console.log(err);
    alert('Link Copy failed')
  }
}
