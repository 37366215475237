import React, { useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import './SelectField.css'

const SelectField = ({
    labelTxt,
    selectOptions,
    inputWrapperStyle,
    errMsg,
    onChange,
    inputId,
    inputValue,
    menuPlacement,
    isMultiValue,
    onInputChange,
    placeholderTxt,
    selectedValue,
    isCreatable,
    selectID,
    maxSelected = Infinity,
    isRequired = false
}) => {
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#807D7D !important',
            fontSize: '13px',
        }),
        container: (provided) => ({
            ...provided,
            marginBottom: '6px'
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#007749 !important',
            minHeight: '30px',
            width: '100%',
            marginBottom: "2px",
            textAlign: 'left',
            boxShadow: 'none', // Removes the box shadow
            borderRadius: '5px',
            outline: 'none',   // Removes the outline
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            minHeight: '30px',
            padding: '5px 6px',
            textAlign: 'left',
            alignItems: 'center',
            height: selectID == "LocationDetails" ? "45px" : "",
            overflow: "auto",
            fontSize: '12px',
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'left',
            fontSize: "13px",
            outline: 'none',   // Removes the outline
        }),
        indicatorSeparator: state => ({
            display: 'none',
            borderLeft: '1px solid'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
        multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: "#E1F5ED",
            fontSize: "13px"
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#000000",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#01774A",
            ':hover': {
                backgroundColor: "#01774A",
                color: '#fff',
            },
            transition: 'all 0.2s ease',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999
        }), 
        singleValue: (provided, state) => ({
            ...provided,
            color: '#000',  // Change this color to your desired single value color
            fontSize: '13px',
        }),
    };
    
    const keywordSelectCustomStyle = {
        ...customStyles,
        control: (provided) => ({
            ...provided,
            minHeight: '60px',
            border:"1px solid #007749 !important",
            boxShadow: 'none', // Removes the box shadow
            outline: 'none',   // Removes the outline
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '5px 6px',
            height: `96px`,
            alignItems: 'flex-start',
            overflow: 'auto',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            alignItems: 'flex-start',
        }),
    };
    
useEffect(()=>{
    if(selectID){
        const designationWrappers = document?.getElementById(selectID)
        const shouldHideAddMore = Object.values(selectedValue).every(value => value === null || value === '' || (Array.isArray(value) && value.length === 0));
              const inputElement = designationWrappers.querySelector('input');    
          if (inputElement) { 
            inputElement.placeholder = shouldHideAddMore ? '' : 'Add More';
            inputElement.style.display = selectedValue?.length >= maxSelected? "none" : "block"
            inputElement.style.width = '100px';
          }
        }
},[inputWrapperStyle,selectedValue]) 
    return (
        <div className={`selectPicker ${inputWrapperStyle}`} id={selectID} style={{ display: "flex", flexDirection: "column" }}>
            {!!labelTxt && <label className='inputLable' htmlFor={inputId}>{labelTxt}{isRequired && <small style={{ color: "#BC0000" }}>*</small>}</label>}
            {isCreatable ? <CreatableSelect menuPlacement={menuPlacement} placeholder={placeholderTxt} value={selectedValue}
            onInputChange={onInputChange}
            inputValue={inputValue}
            onChange={onChange} isMulti={isMultiValue} styles={labelTxt == "Keywords" ? keywordSelectCustomStyle : customStyles} options={selectOptions}
                maxMenuHeight={200} /> : <Select menuPlacement={menuPlacement} id={selectID} placeholder={placeholderTxt} value={selectedValue} onChange={onChange} isMulti={isMultiValue} styles={labelTxt == "Keywords" ? keywordSelectCustomStyle : customStyles} options={selectOptions}
                    maxMenuHeight={200} />}
            {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
        </div>
    )
}

export default SelectField