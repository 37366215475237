import "./UserProfile.css";
import Modal from "react-modal";
import Lottie from "lottie-react";
import { SHA256 } from 'crypto-js';
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { ModuleCards } from "../../constants/ModuleCards";
import { useSelectedCardContext } from "../../Context/Context";
import { PortalUserRole, ResumeApiUrls, ResumeWebURL, Url } from "../../EnvoirnmentSetup/APIs";
import { emailValidationForJobPoster, field, matchPassword, mobileNumberValidations, onPassword, onlyAlphabets, onlyLinkedInProfile, onlyNumber } from "../../Validation/Validation";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import loader from "../../assets/PANLoader.json";
import eyeicon from '../../assets/eyeIcon.svg'
import qUpdate from '../../assets/QUpdate.svg'
import hiddenEyeIcon from '../../assets/hiddenEyeIcon.svg'
import lock from '../../assets/lock.svg'
import employees from '../../assets/Employees.png'
import userProfile from '../../assets/userProfile.svg'
import phoneCallImage from "../../assets/phoneCallImage.svg";
import emailImage from "../../assets/emailImage.svg";
import linkedInImage from "../../assets/linkedInImage.svg";
import editprofile from "../../assets/editprofile.svg";
import { getAPICall, patchAPICall, putAPICall } from "../../APIMethods/APIMethods";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";

function UserProfile() {
    const navigate = useNavigate();

    const { selectedCard, setSelectedCard, setIsLoading, setWarning, setAlert } = useSelectedCardContext()
    const [loader, setLoader] = useState(false)
    const [userId, setUserId] = useState("")
    const [UserDataDetails, setUserDataDetails] = useState({});
    const [userNameCurrent, setUserNameCurrent] = useState(field)
    const [userMobileNumberCurrent, setUserMobileNumberCurrent] = useState(field)
    const [userEmailCurrent, setUserEmailCurrent] = useState(field)
    const [userLinkedInCurrent, setUserLinkedInCurrent] = useState(field)
    const [selectedCountryCodeCurrent, setSelectedCountryCodeCurrent] = useState({})
    const [userName, setUserName] = useState(field)
    const [userMobileNumber, setUserMobileNumber] = useState(field)
    const [userMobileNumber1, setUserMobileNumber1] = useState(field)
    const [userEmail, setUserEmail] = useState(field)
    const [userEmail1, setUserEmail1] = useState(field)
    const [userLinkedIn, setUserLinkedIn] = useState(field)
    const [userLinkedIn1, setUserLinkedIn1] = useState(field)
    const [selectedCountryCode, setSelectedCountryCode] = useState({})
    const [currentPassword, setCurrentPassword] = useState(field)
    const [newPassword, setNewPassword] = useState(field)
    const [confirmPassword, setConfirmPassword] = useState(field)
    const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
    const [editProfileModal, setEditProfileModal] = useState(false)
    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [hidePwd, setHidePwd] = useState({ pwdOne: false, pwdTwo: false, pwdThree: false })
    const [userName1, setUserName1] = useState(field)
    const [editProfileForm, setEditProfileForm] = useState({ name: "", mno: "", cntryCodeId: "249", email: "", linkedIn: "" })
    const [countryCode, setCountryCode] = useState([])
    const [IsResumeAvailable, setIsResumeAvailable] = useState(false)

    const customStylesUpdateModal = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "40%",
            borderRadius: "25px",
            padding: "10px",
            maxWidth: "55%"
        },
        overlay: {
            zIndex: 1
        },
    };
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "50%",
            borderRadius: "25px",
            padding: "10px",
        },
        overlay: {
            zIndex: 1
        },
    };

    useEffect(() => {
        setCurrentPassword(field)
        setNewPassword(field)
        setConfirmPassword(field)
    }, [changePasswordModal])

    useEffect(() => {
        setSelectedCard(ModuleCards.profile)
        getUserCompleteDetails()
        getAPICall(Url.CountryCodes)
            .then((res) => {
                console.log("country code api response", res);
                setCountryCode(res?.DATA)
            })
            .catch((e) => {
                console.log(e);
            })
        const user = JSON.parse(sessionStorage.getItem('user'))
        if (!!user && false) {
            getAPICall(ResumeApiUrls.GetResume.replace('{mobile}', user?.mobileNumber).replace('{countryCodeId}', user?.countryCode?.countryCodeId))
                .then((ResumeDATA) => {
                    if (ResumeDATA.SUCCESS && !ResumeDATA?.DATA) {
                        // window.open(ResumeWebURL, '_blank')
                        // setIsResumeAvailable(true)
                    }
                })
        }
    }, [])

    const getUserCompleteDetails = () => {
        setLoader(true)
        getAPICall(Url.getuser)
            .then((res) => {
                setLoader(false)
                // console.log("userData....", res);
                sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName));
                sessionStorage.setItem("user", JSON.stringify(res?.DATA));

                setUserEmail(emailValidationForJobPoster("Corporate Email", res?.DATA?.userName))
                setUserEmail1(emailValidationForJobPoster("Corporate Email", res?.DATA?.userName))
                setUserMobileNumber(onlyNumber('Mobile Number', res?.DATA?.mobileNumber))
                setUserMobileNumber1(onlyNumber('Mobile Number', res?.DATA?.mobileNumber))
                setUserName(onlyAlphabets('Full Name', res?.DATA?.fullName))
                setUserName1(onlyAlphabets('Full Name', res?.DATA?.fullName))
                setSelectedCountryCode(res?.DATA?.countryCode)
                setUserLinkedIn(onlyLinkedInProfile("LinkedIn Profile", res?.DATA?.linkedinProfile))
                setUserLinkedIn1(onlyLinkedInProfile("LinkedIn Profile", res?.DATA?.linkedinProfile))
                setUserId(res?.DATA?.userId)
                setUserEmailCurrent(emailValidationForJobPoster("Corporate Email", res?.DATA?.userName))
                setUserMobileNumberCurrent(onlyNumber('Mobile Number', res?.DATA?.mobileNumber))
                setUserNameCurrent(onlyAlphabets('Full Name', res?.DATA?.fullName))
                setSelectedCountryCodeCurrent(res?.DATA?.countryCode)
                setUserLinkedInCurrent(onlyLinkedInProfile("LinkedIn Profile", res?.DATA?.linkedinProfile))
            }).catch((e) => console.log(e))
    }
    
    const updateProfile = () => {
        if (userName.isValidField == false || userName.fieldValue == '') {
            setWarning("Invalid Name.")
        } else if (userMobileNumber.isValidField == false || userMobileNumber.fieldValue == '') {
            setWarning("Invalid mobile number.")
        } else if (userEmail.isValidField == false || userEmail.fieldValue == '') {
            setWarning("Invalid email Id.")
        } else if (userLinkedIn.isValidField == false ) {
            setWarning("Invalid LinkedIn profile url.")
        } else {
            try {
                let updatedLinkedInUrl;
                if (userLinkedIn?.fieldValue?.trim()) {
                if (userLinkedIn?.fieldValue?.startsWith("http://www.") || userLinkedIn?.fieldValue?.startsWith("https://www.")) {
                    updatedLinkedInUrl = userLinkedIn?.fieldValue
                }
                else if (userLinkedIn?.fieldValue?.startsWith("www.")) {
                    updatedLinkedInUrl = `https://${userLinkedIn?.fieldValue}`
                }
                else if (userLinkedIn?.fieldValue?.startsWith("https://") || userLinkedIn?.fieldValue?.startsWith("http://")) {
                    let newLink = userLinkedIn?.fieldValue
                    let colIndex = newLink.indexOf(":") + 3
                    let lastIndex = newLink.length
                    let strAfterRemove = newLink.slice(colIndex, lastIndex)
                    updatedLinkedInUrl = `https://www.${strAfterRemove}`
                }
                else {
                    updatedLinkedInUrl = `https://www.${userLinkedIn?.fieldValue}`
                }
                }
                setLoader(true)
                const UserData = {
                    userId: userId,
                    messageAuditId: 0,
                    messageOTP: 1111,
                    emailAuditId: 0,
                    emailOTP: 1111,
                    fullName: userName?.fieldValue,
                    mobileNumber: userMobileNumber?.fieldValue,
                    userName: userEmail?.fieldValue,
                    linkedinProfile: updatedLinkedInUrl?.toLocaleLowerCase(),
                    countryCode: {
                        countryCodeId: selectedCountryCode.countryCodeId,
                    },
                    designation: null,
                    state: null,
                    skill: null,
                    experience: null
                }
                console.log({ UserData })
                putAPICall(Url.getuser, UserData)
                    .then((res) => {
                        setLoader(false)
                        // console.log("userData....", res);
                        if (res?.SUCCESS) {
                            setEditProfileModal(false)
                            getUserCompleteDetails();
                            setAlert(`Profile updated successfully!`);
                            sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName))
                        } else {
                            let originalString = res?.message;
                            if (originalString.includes(':')) {
                                let resultString = originalString
                                    .split(":")
                                    .slice(1)
                                    .join(":")
                                    .trim();
                                setWarning(resultString);
                            } else {
                                setWarning(originalString);
                            }
                        }
                    }).catch((e) => console.log(e))
            } catch (e) {
                console.log(e);
            }
        }
    }

    const updatePassword = () => {
        if (!(!!currentPassword?.fieldValue) && !(!!newPassword?.fieldValue) && !(!!confirmPassword?.fieldValue)) {
            setWarning("Please fill all mandatory fields")
        } else if (!(!!currentPassword?.fieldValue)) {
            setWarning("Please enter old password")
        } else if (!(!!newPassword?.fieldValue)) {
            setWarning("Please enter new password")
        } else if (!(!!confirmPassword?.fieldValue)) {
            setWarning("Please confirm your password")
        } else if (!newPassword?.isValidField) {
            setWarning("Password must contain at least 1 lower case, 1 upper case, 1 numeric, special character, length maximum of 16 and minimum of 8")
        } else if (confirmPassword.isValidField == false) {
            setWarning("Password did not match")
        } else {
            try {
                let token = sessionStorage.getItem("token");
                let currentPASS = SHA256(currentPassword?.fieldValue).toString();
                let newPASS = SHA256(newPassword?.fieldValue).toString();

                let PassObj = {
                    oldPassword: currentPASS,
                    password: newPASS
                }
                setLoader(true)
                patchAPICall(Url.changePassword, PassObj)
                    .then((res) => {
                        // console.log("userData....", res);
                        setLoader(false)
                        if (res?.SUCCESS) {
                            getUserCompleteDetails();
                            setChangePasswordModal(false)
                            setAlert(`Password updated successfully!`);
                        } else {
                            let originalString = res?.message;
                            if (originalString.includes(':')) {
                                let resultString = originalString
                                    .split(":")
                                    .slice(1)
                                    .join(":")
                                    .trim();
                                setWarning(resultString);
                            } else {
                                setWarning(originalString);
                            }
                        }
                    })
                    .catch((e) => console.log(e))
            } catch (e) {
                console.log(e);
            }
        }
    }

    const handleCloseEditModal = () => {
        setEditProfileModal(false);
        setUserName(userName1)
        setUserMobileNumber(userMobileNumber1)
        setUserLinkedIn(userLinkedIn1)
        setUserEmail(userEmail1)
    }
console.log(userLinkedInCurrent);

    return (
        <div className="userProfileWrapper">
            {/* GO Back functionality */}
            <div className="profileTitleDiv">
                <p className="profileBackBtn">
                    Profile
                </p>
            </div>
            <div className="userHomePageImageWrapper">
                <div className="userNameWrapper">
                    <div className="userHello">Hello,</div>
                    <div className="userNameShow">{userNameCurrent?.fieldValue ?? ''}</div>
                    <div className="userHello">{PortalUserRole == "ROLE_MANAGER" ? "College Administrator" : "Placement Co-Ordinator"}</div>
                </div>
                <img src={employees} className="employeesImg" />
            </div>
            <div className="mainDisplyContainer">
                <div className="ContainerOne">
                    <div>
                        <img src={phoneCallImage} className="displyImage" />
                    </div>
                    <span className="showText">+{(selectedCountryCodeCurrent?.countryCode ?? '') + " " + (userMobileNumberCurrent?.fieldValue ?? '')}</span>
                </div>
                <div className="ContainerTwo">
                    <div>
                        <img src={emailImage} className="displyImage" />
                    </div>
                    <span className="showText">{userEmailCurrent?.fieldValue ?? ''}</span>
                </div>
                {userLinkedInCurrent?.fieldValue && 
                <div className="ContainerThree" onClick={() => { window.open(userLinkedInCurrent.fieldValue, '_blank') }}>
                    <div>
                        <img src={userLinkedInCurrent?.fieldValue?.includes('shareresume') ? require('../../assets/favicon.png') : linkedInImage} className="displyImage" />
                    </div>
                    <a href={userLinkedInCurrent?.fieldValue} target="_blank" className="showText">{userLinkedInCurrent?.fieldValue ?? ''}</a>
                </div>}
            </div>
            <div className="editAndcgPwdWrapper">
                <div onClick={() => { }} className="editProfileDiv">
                    <img src={lock} className="editImage" onClick={() => setChangePasswordModal(true)} />
                    <p className="editProfile" onClick={() => setChangePasswordModal(true)}>Change Password</p>
                </div>
                <div className="editProfileDiv">
                    <img src={editprofile} className="editImage" onClick={() => setEditProfileModal(true)} />
                    <p className="editProfile" onClick={() => setEditProfileModal(true)}>Edit Profile</p>
                </div>
            </div>

            <Modal
                isOpen={editProfileModal}
                onRequestClose={handleCloseEditModal}
                ariaHideApp={false}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
                shouldCloseOnOverlayClick={false}
            >
                <div className="deleteModalWrapper">
                    <div className="delAccModalCrossDiv">
                        <p className="delAccModalCrossPara" onClick={handleCloseEditModal}>
                            ×
                        </p>
                    </div>
                </div>
                <div className="editProImgDiv">
                    <span>Edit Your Profile</span>
                    {/* <img src={userProfile} /> */}
                </div>
                <div className="userProfileFields">
                    <div className="userProfileFieldRow">
                        <div className="userProfileInputDiv">
                            <label>Name<span style={{ color: "#BC0000" }}>*</span></label>
                            <input type="text" value={userName?.fieldValue} maxLength={60} onChange={(e) => setUserName(onlyAlphabets('Full Name', e.target.value))} />
                            <p className="errorMsg">{userName?.errorField}</p>
                        </div>
                        <div className="userProfileInputDiv" >
                            <label>Mobile No<span style={{ color: "#BC0000" }}>*</span></label>
                            <div className="userProfileMnoDiv">
                                <select disabled value={selectedCountryCode?.countryCode} onChange={(e) => {
                                    console.log("countyrcode", countryCode[e.target.selectedIndex])
                                    setSelectedCountryCode(countryCode[e.target.selectedIndex])
                                }}>
                                    {countryCode.map((item) => {
                                        return (
                                            <option >+{item?.countryCode}</option>
                                        )
                                    })}
                                </select>
                                <input type="number" value={userMobileNumber?.fieldValue} 
                                onChange={(e) => {
                                    if(e.target.value?.length<=10){
                                    setUserMobileNumber(mobileNumberValidations('Mobile Number', e.target.value))
                                    }
                                    }}
                                     />
                            </div>
                            <p className="errorMsg">{userMobileNumber?.errorField}</p>
                        </div>
                    </div>
                    <div className="userProfileFieldRow" style={{ marginTop: "2%" }}>
                        <div className="userProfileInputDiv">
                            <label>Email Id<span style={{ color: "#BC0000" }}>*</span></label>
                            <input type="email" value={userEmail?.fieldValue} onChange={(e) => setUserEmail(emailValidationForJobPoster("Corporate Email", e.target.value))} />
                            <p className="errorMsg">{userEmail?.errorField}</p>
                        </div>
                        <div className="userProfileInputDiv" >
                            <label>LinkedIn </label>
                            <input type="text" value={userLinkedIn?.fieldValue} onChange={(e) => setUserLinkedIn(onlyLinkedInProfile("LinkedIn Profile", e.target.value))} />
                            <p className="errorMsg">{userLinkedIn?.errorField}</p>
                        </div>
                    </div>
                    <div className="userProfileBtnDiv">
                        <button className="userProBtnOne" onClick={handleCloseEditModal}>Cancel</button>
                        <button className="userProBtnTwo" onClick={() => { updateProfile() }}>
                            {/* <img src={qUpdate} alt="Something went wrong" /> */}
                            Update
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={changePasswordModal}
                onRequestClose={() => setChangePasswordModal(false)}
                ariaHideApp={false}
                // style={customStyles1}
                contentLabel="Example Modal"
                overlayClassName={'modalOverlayStyle'}
                className={`modalStyle`}
            >
                <div className="passwordDiv">
                    <div className="deleteModalWrapper">
                        <div className="delAccModalCrossDiv">
                            <p className="delAccModalCrossPara" onClick={() => {
                                setChangePasswordModal(false);
                            }}>
                                ×
                            </p>
                        </div>
                    </div>
                    <div className="editProFormOuterDiv" style={{ width: window.screen.width < 1020 && "80vw" }}>
                        <h2>Change your password</h2>
                        <div className="editProField">
                            <label>Enter old password<span>*</span></label>
                            <div className="editProfileFieldDiv">
                                <input type={hidePwd.pwdOne == true ? "text" : "password"} placeholder="Old password" value={currentPassword?.fieldValue} onChange={(e) => { if (e?.target?.value.length < 17) { setCurrentPassword(onPassword(e.target.value)) } }} />
                                <img style={{ marginTop: hidePwd?.pwdOne == true ? "0px" : "-1%" }} onClick={() => setHidePwd({ ...hidePwd, pwdOne: !hidePwd.pwdOne })} src={hidePwd.pwdOne == true ? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" alt="" />
                            </div>
                            <p className="errorMsg">{currentPassword?.errorField}</p>
                        </div>
                        <div className="editProField">
                            <label>Enter new password<span>*</span></label>
                            <div className="editProfileFieldDiv">
                                <input type={hidePwd.pwdTwo == true ? "text" : "password"} placeholder="New password" value={newPassword?.fieldValue} onChange={(e) => { if (e?.target?.value.length < 17) { setNewPassword(onPassword(e.target.value));setConfirmPassword({...field,fieldValue:''}) }}} />
                                <img style={{ marginTop: hidePwd?.pwdTwo == true ? "0px" : "-1%" }} onClick={() => setHidePwd({ ...hidePwd, pwdTwo: !hidePwd.pwdTwo })} src={hidePwd.pwdTwo == true ? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" alt="" />
                            </div>
                            <p className="errorMsg">{newPassword?.errorField}</p>
                        </div>
                        <div className="editProField">
                            <label>Confirm password<span>*</span></label>
                            <div className="editProfileFieldDiv">
                                <input type={hidePwd.pwdThree == true ? "text" : "password"} placeholder="Confirm password" value={confirmPassword?.fieldValue} onChange={(e) => setConfirmPassword(matchPassword(e.target.value, newPassword?.fieldValue))} />
                                <img style={{ marginTop: hidePwd?.pwdThree == true ? "0px" : "-1%" }} onClick={() => setHidePwd({ ...hidePwd, pwdThree: !hidePwd.pwdThree })} src={hidePwd.pwdThree == true ? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" alt="" />
                            </div>
                            <p className="errorMsg">{confirmPassword?.errorField}</p>
                        </div>
                        <div className="editProBtn">
                            <button className="EditProfCancelBtn" onClick={() => setChangePasswordModal(false)}>Cancel</button>
                            <button className="EdirProfChangeBtn" onClick={() => { updatePassword() }}>Change</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <ComponentAndScreenConstant.CommonModal
                isOpen={IsResumeAvailable}
                onRequestClose={() => setIsResumeAvailable(false)}
            >
                <div className="redirectToResume">
                    <button>x</button>
                    <Lottie
                        loop={true}
                        animationData={require('../../assets/Opps.json')}
                        className="delAccModalErrLottie"
                    />
                    <p>You don't have resume please create resume to <br />complete your profile</p>
                    <div className="resumeButtonDiv">
                        <button onClick={() => setIsResumeAvailable(false)}>
                            Cancel
                        </button>
                        <button
                            style={{ backgroundColor: '#007749', color: '#fff' }}
                            onClick={() => {
                                window.open(ResumeWebURL, '_blank')
                                setIsResumeAvailable(false)
                            }}>
                            OK
                        </button>
                    </div>
                </div>
            </ComponentAndScreenConstant.CommonModal>
            <ComponentAndScreenConstant.LoaderModal setLoader={loader} />
        </div>
    );
}

export default UserProfile;
