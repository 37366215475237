import React, { useEffect, useState } from "react";
import "./OverAllJobPostings.css";
import { useSelectedCardContext } from "../../../Context/Context";
import { getAPICall } from "../../../APIMethods/APIMethods";
import { Url } from "../../../EnvoirnmentSetup/APIs";

const OverAllJobPostings = () => {
  const { setIsLoading, setWarning } = useSelectedCardContext();

  const [tableData, setTableData] = useState([]);
  const [tableHeadings, setTableHeadings] = useState({
    srno: "",
    heading: "",
    date1: "",
    date2: "",
    date3: "",
    date4: "",
    date5: "",
    date6: "",
    date7: "",
  });

  const getTableData = async () => {
    try {
      setIsLoading(true);

      const weeklyData = await getAPICall(Url?.managerReport);

      if (weeklyData?.SUCCESS) {
        setIsLoading(false);
        const allData = weeklyData?.DATA;
        let dayReport = allData[0]?.dayReport;
        let headerData = {
          srno: "Sr NO",
          heading: "Recruiter Email",
          date1: dayReport[0]?.date,
          date2: dayReport[1]?.date,
          date3: dayReport[2]?.date,
          date4: dayReport[3]?.date,
          date5: dayReport[4]?.date,
          date6: dayReport[5]?.date,
          date7: dayReport[6]?.date,
        };
        setTableHeadings(headerData);

        let tableData = [];
        let obj = {};

        allData?.forEach((row, i) => {
          obj = {
            ...obj,
            srno: i + 1,
            fullname: row?.user?.fullName,
            username: row?.user?.userName,
            linkedinProfile: row?.user?.linkedinProfile,
            first: row?.dayReport[0]?.jobCount,
            second: row?.dayReport[1]?.jobCount,
            third: row?.dayReport[2]?.jobCount,
            fourth: row?.dayReport[3]?.jobCount,
            fifth: row?.dayReport[4]?.jobCount,
            sixth: row?.dayReport[5]?.jobCount,
            seventh: row?.dayReport[6]?.jobCount,
          };
          tableData.push(obj);
        });
        setTableData(tableData);
      } else {
        setIsLoading(false);
        setWarning("Failed to fetch the data.");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  const handleNavigationClick = (link) => {
    window.open(link);
  };

  return (
    <div className="overalljobswrapper">
      <div className="overalljobsheader">
        <p>Overall Job Postings</p>
      </div>

      <div className="jobpostingstable">
        <table>
          <thead>
            <tr>
              <th>{tableHeadings?.srno}</th>
              <th>{tableHeadings?.heading}</th>
              <th>{tableHeadings?.date1}</th>
              <th>{tableHeadings?.date2}</th>
              <th>{tableHeadings?.date3}</th>
              <th>{tableHeadings?.date4}</th>
              <th>{tableHeadings?.date5}</th>
              <th>{tableHeadings?.date6}</th>
              <th>{tableHeadings?.date7}</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item, index) => {
              return (
                <tr key={`key${index + 1}`}>
                  <td>{item?.srno}</td>
                  <td>
                    {item?.linkedinProfile ? (
                      <span
                        style={{ color: "blue" , cursor:"pointer"}}
                        onClick={() =>
                          handleNavigationClick(item?.linkedinProfile)
                        }
                        className="linkedinLink"
                      >
                        {item?.username}
                      </span>
                    ) : (
                      <span>{item?.username}</span>
                    )}
                  </td>
                  <td>{item?.first}</td>
                  <td>{item?.second}</td>
                  <td>{item?.third}</td>
                  <td>{item?.fourth}</td>
                  <td>{item?.fifth}</td>
                  <td>{item?.sixth}</td>
                  <td>{item?.seventh}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OverAllJobPostings;
