import React, { useEffect, useState } from 'react'
import moment from 'moment'
import './ApplicantDetailsCard.css'
import noRecordFound from '../../assets/nodataavailable.svg'
import Suitcase from '../../assets/experienceIcon.png'
import userLogo from '../../assets/userNewLogo.svg'
import confirmationLogo from '../../assets/shareJobIcon.svg'
import completed from '../../assets/Success.json'
import ReactModal from 'react-modal'
import Oops from "../../assets/Opps.json";
import HireBtn from '../../assets/HireBtn.svg'
import DeleteApplicantBtn from '../../assets/DeleteBtn.svg'
import { Url } from '../../EnvoirnmentSetup/APIs'
import Lottie from 'lottie-react'
const ApplicantDetailsCard = ({
    applicantDetails,
    onCallClick,
    onLinkedInClick,
    onShortlistClick,
    onScheduledClick,
    onMailClick,
    onMessageClick,
    onHireClick,
    flow,
    onDeclinedClick,
    refreshData,
    setRefereshData,
}) => {
    const [confirmationPopup, setConfirmationPopup] = useState(false)
    const [successMsg, setsuccessMsg] = useState("");
    const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
    const [trackRecordModal, setTrackRecordModal] = useState(false)
    const [trackaRecordData, setTrackaRecordData] = useState({
        userId: "",
        userName: "",
        keyWord: "",
        jobRole: "",
        AppliedDate: "",
        record: ""
    });

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "40%",
            maxWidth: "85%",
            borderRadius: "25px",
            padding: "10px",
        },
    };
    const trackRecordStyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: window.screen.width > 1024 ? "50%" : "90%",
            borderRadius: "25px",
            padding: "10px",
        },
    };

    const ApplictionActioncustomStylesDeleteModal = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "40%",
            maxWidth: "80%",
            borderRadius: "25px",
            padding: "10px",
        },
    };
    function openSuccessModal() {
        setSuccessModalIsOpen(true);
    }

    function closeSuccessModal() {
        setSuccessModalIsOpen(false);
    }

    useEffect(() => {
        GetApplicantList();
    }, [successMsg])
    
    const handleConfirmSubmit = () => {
        try {
            let token = sessionStorage.getItem("token");
            let DisscussionData = {
                recruiterNote: trackaRecordData?.record,
                user: {
                    userId: trackaRecordData?.userId,
                },
                job: {
                    jobId: applicantDetails?.job?.jobId,
                },
                currentCTC: trackaRecordData?.currentCTC,
                expectedCTC: trackaRecordData?.expectedCTC,
                education: trackaRecordData?.education,
                noticePeroid: trackaRecordData?.noticePeriod
            };
            console.log("DisscussionData", DisscussionData);

            fetch(Url?.ChangeJobPostStatus, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(DisscussionData),
            })
                .then((response) => response.json())
                .then((res) => {
                    setConfirmationPopup(false)
                    if (res.SUCCESS) {
                        setsuccessMsg(
                            `The track record updated successfully!`
                        );
                        openSuccessModal();
                        setTrackRecordModal(false)
                        GetApplicantList();

                    } else {
                        let originalString = res?.message;
                        if (originalString.includes(':')) {
                            let resultString = originalString
                                .split(":")
                                .slice(1)
                                .join(":")
                                .trim();
                            // setError(resultString);
                        } else {
                            // setError(originalString);
                        }
                        // openFailModal();
                    }
                });
        } catch (error) {
            console.log("called applicant err", error);
            setConfirmationPopup(false)
        }
    }
    const GetApplicantList = async () => {
        let token = sessionStorage.getItem("token");
        let jobData = {
            intrested: true,
            job: {
                jobId: applicantDetails?.job?.jobId,
            }
        }
        try {
            fetch(Url?.GetAppliedApplicantList, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(jobData),
            })
                .then((response) => response.json())
                .then((res) => {
                    if (res.SUCCESS) {
                        setRefereshData(true)
                    }
                });
        } catch (error) {
            console.log("Job applicant", error);
        } finally {
            //   setIsLoading(false);
        }
    };
    const setDiscussionData = async () => {
        if (!!trackaRecordData.record) {
            try {
                let token = sessionStorage.getItem("token");
                let DisscussionData = {
                    recruiterNote: trackaRecordData?.record,
                    user: {
                        userId: trackaRecordData?.userId,
                    },
                    job: {
                        jobId: applicantDetails?.job?.jobId,
                    },
                    currentCTC: trackaRecordData?.currentCTC,
                    expectedCTC: trackaRecordData?.expectedCTC,
                    education: trackaRecordData?.education,
                    noticePeroid: trackaRecordData?.noticePeriod
                };
         

                fetch(Url?.ChangeJobPostStatus, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(DisscussionData),
                })
                    .then((response) => response.json())
                    .then((res) => {
                        if (res.SUCCESS) {
                            setsuccessMsg(
                                `The track record updated successfully!`
                            );
                            openSuccessModal();
                            setTrackRecordModal(false)
                            GetApplicantList();

                        } else {
                            let originalString = res?.message;
                            if (originalString.includes(':')) {
                                let resultString = originalString
                                    .split(":")
                                    .slice(1)
                                    .join(":")
                                    .trim();
                                
                            } else {
                                GetApplicantList()
                            }
                            setTrackRecordModal(false)
                        }
                    });
            } catch (error) {
                console.log("called applicant err", error);
            }
        }
        else {
            setConfirmationPopup(true)
        }
    };
    const handleInputChange = (e, field) => {
        if (field == "Current CTC" && /^(?!\.)\d*\.?\d*$/.test(e?.target?.value)) {
            setTrackaRecordData({ ...trackaRecordData, currentCTC: e?.target?.value })
        } else if (field == "Expected CTC" && /^(?!\.)\d*\.?\d*$/.test(e?.target?.value)) {
            setTrackaRecordData({ ...trackaRecordData, expectedCTC: e?.target?.value })
        }
        else if (field == "Notice Period") {
            if (/^\d*$/.test(e?.target?.value)) {
                setTrackaRecordData({ ...trackaRecordData, noticePeriod: e?.target?.value })
            }
        }
        else if (field == "Education") {
            if (/^[a-zA-Z:;.,/()\-\s]*$/.test(e?.target?.value)) {
                setTrackaRecordData({ ...trackaRecordData, education: e?.target?.value })
            }
        }
    }
    const handleTrackRecords = (item) => {
        setTrackaRecordData({
            userId: item?.userId,
            userName: item?.fullName,
            keyWord: item?.keywordsString,
            jobRole: item?.job?.designation[0]?.designationName,
            AppliedDate: moment(item?.appliedDate).format("DD-MMM-YYYY"),
            record: !!item?.recruiterNote ? item?.recruiterNote : "",
            education: !!item?.education ? item?.education : "",
            noticePeriod: !!item?.noticePeroid ? item?.noticePeroid : "",
            currentCTC: !!item?.currentCTC ? item?.currentCTC : "",
            expectedCTC: !!item?.expectedCTC ? item?.expectedCTC : ""
        })
        setTrackRecordModal(true)
        setRefereshData(!refreshData)
    }
    return (
      <>
        {flow != "Applied" ? (
          <div className="applicantDetailsCard">
            <div className="applicantLineDiv" />
            <div className="flexDiv spaceBetweenDiv">
              <p className="userName">{applicantDetails?.fullName}</p>
            </div>
            <div className="flexDiv">
              <img
                src={require("../../assets/skillsIcon.png")}
                alt=""
                className="iconImage"
              />
              <span>
                {applicantDetails?.skill?.length > 0
                  ? applicantDetails?.skill
                      ?.map((j, index) => j?.name)
                      .join(", ")
                  : "No Keywords matched"}
              </span>
            </div>
            <div className="flexDiv">
              <img
                src={require("../../assets/experienceIcon.png")}
                alt=""
                className="iconImage"
              />
              <span>{applicantDetails?.experience?.experience}</span>
            </div>
            <div className="flexDiv spaceBetweenDiv">
              <div className="flexDiv">
                {applicantDetails?.appliedDate ? (
                  <>
                    <img
                      src={require("../../assets/calenderIcon.png")}
                      alt=""
                      className="iconImage"
                    />
                    <span>
                      {applicantDetails?.appliedDate
                        ? moment(applicantDetails?.appliedDate).format(
                            "DD-MMM-YYYY"
                          )
                        : "DD-MM-YYYY"}
                    </span>
                  </>
                ) : null}
              </div>
              <div className="flexDiv">
                <img
                  onClick={onLinkedInClick}
                  src={require("../../assets/linkedInIcon.png")}
                  alt=""
                    title='View Profile'
                  className="actionIconImage"
                />
                <img
                  onClick={onCallClick}
                  src={require("../../assets/call_Icon.png")}
                  alt=""
                    title='Contact'
                  className="actionIconImage"
                />
                <img
                  onClick={onMailClick}
                  src={require("../../assets/mailIcon.png")}
                  alt=""
                  title='Send Email'
                  className="actionIconImage"
                />
                <img
                  onClick={onMessageClick}
                  src={require("../../assets/messageIcon.png")}
                  alt=""
                  title='WhatsApp Chat'
                  className="actionIconImage"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="applicantDetailsCard">
            <div className="cardDetails">
              <div className="applicantLineDiv" />
              <div className="flexDiv spaceBetweenDiv">
                <p className="userName">{applicantDetails?.fullName}</p>
              </div>
              <div className="flexDiv">
                <img
                  src={require("../../assets/skillsIcon.png")}
                  alt=""
                  className="iconImage"
                />
                <span>
                  {applicantDetails?.skill?.length > 0
                    ? applicantDetails?.skill
                        ?.map((j, index) => j?.name)
                        .join(", ")
                    : "No Keywords matched"}
                </span>
              </div>
              <div className="flexDiv expdatewrapper">
                <div className="flexDiv">
                  <img
                    src={require("../../assets/experienceIcon.png")}
                    alt=""
                    className="iconImage"
                  />
                  <span>{applicantDetails?.experience?.experience}</span>
                </div>
              </div>
              
              <div className="flexDiv expdatewrapper">
                <div className="flexDiv">
                  {applicantDetails?.appliedDate ? (
                    <>
                      <img
                        src={require("../../assets/calenderIcon.png")}
                        alt=""
                        className="iconImage"
                      />
                      <span>
                        {applicantDetails?.appliedDate
                          ? moment(applicantDetails?.appliedDate).format(
                              "DD-MMM-YYYY"
                            )
                          : "DD-MM-YYYY"}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="flexDiv spaceBetweenDiv"> 
                <div className="flexDiv adminActionBtns">
                  
                <img
                    onClick={onLinkedInClick}
                    src={require("../../assets/linkedInIcon.png")}
                    alt=""
                    title='View Profile'
                    className="actionIconImage"
                  />
                  <img
                    onClick={onCallClick}
                    title='Contact'
                    src={require("../../assets/call_Icon.png")}
                    alt=""
                    className="actionIconImage"
                  />
                  <img
                    onClick={onMailClick}
                    src={require("../../assets/mailIcon.png")}
                    alt=""
                    title='Send email'
                    className="actionIconImage"
                  />
                  <img
                    onClick={onMessageClick}
                    src={require("../../assets/messageIcon.png")}
                    alt=""
                    title='WhatsApp Chat'
                    className="actionIconImage"
                  />
                  <img
                    onClick={onShortlistClick}
                    src={require("../../assets/shortlistIcon.png")}
                    alt=""
                    title='Shortlist for Interview'
                    className="actionIconImage"
                  />
                  <img
                    onClick={onScheduledClick}
                    src={require("../../assets/interViewing.png")}
                    alt=""
                    title='Schedule Interview'
                    className="actionIconImage"
                  />
                </div>
              </div>
            </div>
            <div className="recordDetailsSection">
              <div className="recordDetails">
                {!!applicantDetails?.recruiterNote ? (
                  <div className="recruiterNoteText">
                    {applicantDetails?.recruiterNote}
                  </div>
                ) : (
                  <div className="noRecordDivNew">
                    <img src={noRecordFound} alt="" />
                    <span>No Records Available.</span>
                  </div>
                )}
              </div>
              <div
                className="TRmodalBtnsNew"
                style={{
                  borderTop: "0.5px solid gray",
                  alignItems: "end",
                  padding: "10px 0",
                }}
              >
                <button onClick={() => handleTrackRecords(applicantDetails)}>
                  Add Record
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: window.screen.width > 1020 ? "80%" : "100%",
                    fontSize: "11px",
                    justifyContent: "space-between",
                    color: "#007749",
                    fontWeight: "medium",
                  }}
                >
                  {!!applicantDetails?.education && (
                    <div
                      className="eduDivApplied"
                      title={applicantDetails?.education}
                      style={{
                        maxWidth: "95%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        marginBottom: "3px",
                      }}
                    >
                      Education:{" "}
                      <span style={{ fontWeight: "normal", color: "black" }}>
                        {applicantDetails?.education}
                      </span>
                    </div>
                  )}
                  <div
                    className="moreDetailsApplicant"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "95%",
                    }}
                  >
                    {!!applicantDetails?.noticePeroid && (
                      <span title={applicantDetails?.noticePeroid}>
                        Notice Period:{" "}
                        <span style={{ fontWeight: "normal", color: "black" }}>
                          {applicantDetails?.noticePeroid}{" "}
                          {applicantDetails?.noticePeroid == 1
                            ? "month"
                            : "months"}
                        </span>
                      </span>
                    )}
                    {!!applicantDetails?.currentCTC && (
                      <span title={applicantDetails?.currentCTC}>
                        Current CTC:{" "}
                        <span style={{ fontWeight: "normal", color: "black" }}>
                          {applicantDetails?.currentCTC}{" "}
                          {applicantDetails?.currentCTC ? "LPA" : ""}
                        </span>
                      </span>
                    )}
                    {!!applicantDetails?.expectedCTC && (
                      <span title={applicantDetails?.expectedCTC}>
                        Expected CTC:{" "}
                        <span style={{ fontWeight: "normal", color: "black" }}>
                          {applicantDetails?.expectedCTC}{" "}
                          {applicantDetails?.expectedCTC ? "LPA" : ""}
                        </span>
                      </span>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
              {/* <button type="button" onClick={()=>{setTrackRecordModal(true)}}>Add Record</button> */}
            </div>
            {applicantDetails.selected ? (
              <span className="alreadyHire">Already hired</span>
            ) : (
              <div className="trackBtns">
                <button className="hireButton" title='Deny' onClick={onDeclinedClick}>
                  <img src={DeleteApplicantBtn} alt="" />
                </button>
                <button className="hireButton" title='Hire' onClick={onHireClick}>
                  <img src={HireBtn} alt="" />
                </button>
              </div>
            )}
          </div>
        )}

        <ReactModal
          isOpen={trackRecordModal}
          onRequestClose={() => setTrackRecordModal(false)}
          ariaHideApp={false}
          style={trackRecordStyle}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="deleteModalWrapper">
            <div className="delAccModalCrossDiv">
              <p
                className="delAccModalCrossPara"
                onClick={() => setTrackRecordModal(false)}
              >
                ×
              </p>
            </div>
          </div>

          <div className="TRTitleDiv">
            <span>Track Records</span>
          </div>
          <div className="TRModalContentDiv">
            <div className="TRmodalDetails">
              <div>
                <div className="TRmodalSubDetailsOne">
                  <img src={userLogo} />
                  <span>{trackaRecordData?.userName}</span>
                </div>
                <div className="TRmodalSubDetailsOne">
                  <img src={Suitcase} />
                  <span>{trackaRecordData?.jobRole}</span>
                </div>
              </div>
              <div className="TRmodalSubDetailsTwo">
                <span>
                  Keywords:{" "}
                  <span style={{ color: "#909090" }}>
                    {!!trackaRecordData?.keyWord
                      ? trackaRecordData?.keyWord
                      : "No Keywords matched"}
                  </span>
                </span>
                {/* <span>Total Experience: <span style={{color:"#909090"}}>3 Years</span></span> */}
                <span>
                  Applied Date:{" "}
                  <span style={{ color: "#909090" }}>
                    {trackaRecordData?.AppliedDate}
                  </span>
                </span>
              </div>
            </div>
            <div className="TREditFieldsDiv">
              <div className="TREditSubDiv">
                <div>
                  <label>Education:</label>
                  <input
                    placeholder="Enter education"
                    maxLength={100}
                    value={trackaRecordData?.education}
                    onChange={(e) => handleInputChange(e, "Education")}
                  />
                </div>
                <div>
                  <label>Notice Period (in months):</label>
                  <input
                    placeholder="Enter notice period"
                    value={trackaRecordData?.noticePeriod}
                    maxLength={2}
                    onChange={(e) => handleInputChange(e, "Notice Period")}
                  />
                </div>
              </div>
              <div className="TREditSubDiv">
                <div>
                  <label>Current CTC:</label>
                  <input
                    placeholder="Enter salary in CTC only"
                    maxLength={4}
                    value={trackaRecordData?.currentCTC}
                    onChange={(e) => handleInputChange(e, "Current CTC")}
                  />
                </div>
                <div>
                  <label>Expected CTC:</label>
                  <input
                    placeholder="Enter salary in CTC only"
                    maxLength={4}
                    value={trackaRecordData?.expectedCTC}
                    onChange={(e) => handleInputChange(e, "Expected CTC")}
                  />
                </div>
              </div>
            </div>
            <div className="TRtxtAreaDiv">
              <label>Track Records :</label>
              <textarea
                placeholder="Enter here"
                onChange={(e) =>
                  setTrackaRecordData({
                    ...trackaRecordData,
                    record: e.target.value,
                  })
                }
                value={trackaRecordData?.record}
                maxLength={500}
              />
              {!!!trackaRecordData.record && (
                <div className="noRecordDiv">
                  <img src={noRecordFound} alt="" />
                  <span>No Records Available</span>
                </div>
              )}
              <div style={{ width: "100%" }}>
                <p
                  style={{ padding: "0px", margin: "0px", textAlign: "right" }}
                >
                  {trackaRecordData?.record?.length}/500
                </p>
              </div>
            </div>
            <div className="TRmodalBtns">
              <button onClick={() => setTrackRecordModal(false)}>Cancel</button>
              <button onClick={() => setDiscussionData()}>Submit</button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={confirmationPopup}
          onRequestClose={() => setConfirmationPopup(false)}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="delAccModal1">
            <div className="delAccModalCrossDiv">
              <p
                onClick={() => setConfirmationPopup(false)}
                className="delAccModalCrossPara"
              >
                ×
              </p>
            </div>
          </div>
          <div className="modalImageDiv">
            <Lottie animationData={Oops} style={{ width: "25%" }} />
            {/* <img src={confirmationLogo} style={{ width: "100px" }} /> */}
          </div>
          <div
            className="delAccModalErrMsg"
            style={{
              fontWeight: "normal",
              margin: 0,
              padding: "10px 0 25px 0",
            }}
          >
            Are you sure you want to submit without entering value?
          </div>
          <div className="okBtnModal" style={{ display: "flex", gap: "2%" }}>
            <button onClick={handleConfirmSubmit}>Yes</button>
            <button
              onClick={() => setConfirmationPopup(false)}
              style={{
                backgroundColor: "#ffff",
                border: "0.5px solid #007749",
                color: "#007749",
              }}
            >
              No
            </button>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={SuccessModalIsOpen}
          onRequestClose={closeSuccessModal}
          ariaHideApp={false}
          style={ApplictionActioncustomStylesDeleteModal}
          contentLabel="Example Modal"
        >
          <div className="delAccModal1">
            <div className="delAccModalCrossDiv">
              <p onClick={closeSuccessModal} className="delAccModalCrossPara">
                ×
              </p>
            </div>
          </div>
          <div>
            <Lottie
              animationData={completed}
              loop={true}
              className="delAccModalErrLottie"
            />
          </div>
          <div className="delAccModalErrMsg">{successMsg}</div>
          <div className="modalOkBtnDiv">
            <button className="modalOkBtn" onClick={closeSuccessModal}>
              OK
            </button>
          </div>
        </ReactModal>
      </>
    );
}

export default ApplicantDetailsCard
