export const ModuleCards = {
    LatestJobs: 1,
    teamManagement: 2,
    regStudent :3,
    studentRepo: 4,
    QuikHireShow : 5,
    instituteInformation : 6,
    quickhirejobs : 7,
    insights : 8,

}