import React from 'react';
import './ApplicationStatus.css'
import moment from 'moment';

const ApplicationStatusBar = props => {
  const ApplicationStatus = props.ApplicationStatus;
  console.log({ ApplicationStatus });

  return (
    <div className='statusMainContainer'>
      <div className='statusLine startAndEnd appliedStatusContainer' />
      <div className='toolTipContainer appliedStatusContainer' >
        <span className="tooltipText appliedStatusContainerToolTip">
          Applied
          <br />
          {ApplicationStatus?.intrested
            ? moment(ApplicationStatus?.intrestedDate).format('DD-MM-YYYY')
            : <small>DD-MM-YYYY</small>}
        </span>
      </div>
      {/* {ApplicationStatus?.recruiterView && <> */}
      <div className={`statusLine ${ApplicationStatus?.recruiterView ? 'viewedStatusContainer' : 'grayContentContainer'}`} />
      <div className={`toolTipContainer ${ApplicationStatus?.recruiterView ? 'viewedStatusContainer' : 'grayContentContainer'}`}  >
        <span className={`tooltipText ${ApplicationStatus?.recruiterView ? 'viewedStatusContainerToolTip' : 'grayContentContainer'}`}>
          Viewed
          <br />
          {ApplicationStatus?.recruiterView ?
            moment(ApplicationStatus?.recruiterViewDate).format('DD-MM-YYYY')
            : <small>DD-MM-YYYY</small>}
        </span>
      </div>
      {/* </>} */}
      {/* {ApplicationStatus?.recruiterContacted && <> */}
      <div className={`statusLine ${ApplicationStatus?.recruiterContacted ? 'contactedStatusContainer' : 'grayContentContainer'}`} />
      <div className={`toolTipContainer ${ApplicationStatus?.recruiterContacted ? 'contactedStatusContainer' : 'grayContentContainer'}`}>
        <span className={`tooltipText ${ApplicationStatus?.recruiterContacted ? 'contactedStatusContainerToolTip' : 'grayContentContainer'}`}>
          Contacted
          <br />
          {ApplicationStatus?.recruiterContacted ?
            moment(ApplicationStatus?.recruiterContactedDate).format('DD-MM-YYYY')
            : <small>DD-MM-YYYY</small>}
        </span>
      </div>
      {/* </>} */}
      {/* {(ApplicationStatus?.closed || ApplicationStatus?.selected) && <> */}
      <div className={`statusLine ${ApplicationStatus?.selected ? 'offeredStatusContainer' : ApplicationStatus?.closed ? 'closedStatusContainer' : 'grayContentContainer'}`} />
      <div className={`toolTipContainer ${ApplicationStatus?.selected ? 'offeredStatusContainer' : ApplicationStatus?.closed ? 'closedStatusContainer' : 'grayContentContainer'}`} >
        <span className={`tooltipText ${ApplicationStatus?.selected ? 'offeredStatusContainerToolTip' : ApplicationStatus?.closed ? 'closedStatusContainerToolTip' : 'grayContentContainer'}`}>
          {ApplicationStatus?.selected ? 'Offered' : ApplicationStatus?.closed ? 'Denied' : 'Offered'}
          <br />
          {ApplicationStatus?.closed
            ? moment(ApplicationStatus?.closedDate).format('DD-MM-YYYY')
            : ApplicationStatus?.selected
              ? moment(ApplicationStatus?.selectedDate).format('DD-MM-YYYY')
              : <small>DD-MM-YYYY</small>}
        </span>
      </div>
      <div className={`statusLine startAndEnd  ${ApplicationStatus?.selected ? 'offeredStatusContainer' : ApplicationStatus?.closed ? 'closedStatusContainer' : 'grayContentContainer'}`} />
      {/* </>} */}
    </div>
  );
};

export default ApplicationStatusBar;
