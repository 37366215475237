import React from 'react'
import './InputText.css'

const InputText = ({
  labelTxt,
  inputType,
  inputClass,
  inputValue,
  onChange,
  inputStyle,
  inputWrapperStyle,
  readOnly,
  errMsg,
  inputId,
  placeholderTxt,
  isRequired = false,
  maxLength = 100
}) => {
  return (
    <div className={`inputWrapper ${inputWrapperStyle}`} style={{ display: "flex", flexDirection: "column" }}>
      {!!labelTxt && <label className='inputLable' htmlFor={inputId}>{labelTxt}{isRequired && <span style={{ color: "#BC0000", fontSize: '14px' }}>*</span>}</label>}
      <input type={inputType} id={inputId} maxLength={inputType == "number" ? 10 : maxLength} readOnly={readOnly} className={`inputTextClass ${inputClass}`} placeholder={placeholderTxt} style={inputStyle} value={inputValue} onChange={onChange} />
      {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
    </div>
  )
}

export default InputText